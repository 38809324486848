import React from "react";

function PaymentProcessor() {
     return (
          <>
               <div className="website-container">
                    <div className="px-16 py-11">
                         <div className="mb-14">
                              <h1 className="text-4xl font-bold mb-4">Seamlessly Accept Payments Online with TrackmyDoc</h1>
                              <p className="text-lg text-gray-700">
                                   Make online payments smooth and secure by connecting your TrackmyDoc account. Use the best time-tracking
                                   software, AI-powered CRM platforms, and many more tools to easily accept credit/debit cards, including
                                   Google Pay and bank debit payments through TrackmyDoc’s secure integrated processors.
                              </p>
                         </div>

                         <div className="mb-14">
                              <h2 className="text-2xl font-semibold mb-4">Why Choose TrackmyDoc for Payment Processing?</h2>
                              <ul className="list-disc list-inside space-y-2 text-gray-700">
                                   <li>
                                        <strong>Secure Transactions:</strong> Ensure client payments are safe and compliant with industry
                                        standards.
                                   </li>
                                   <li>
                                        <strong>Flexible Payment Options:</strong> Accept credit/debit cards, Google Pay, and bank debit
                                        payments effortlessly.
                                   </li>
                                   <li>
                                        <strong>Streamlined Integration:</strong> Connect your TrackmyDoc account to leading payment
                                        processors for a seamless experience.
                                   </li>
                              </ul>
                         </div>

                         <div className="mb-14">
                              <h2 className="text-2xl font-semibold mb-4">Features of Our Payment Solutions</h2>
                              <div className="space-y-6">
                                   <div>
                                        <h3 className="text-xl font-semibold">1. Integrated Payment Processors</h3>
                                        <p className="text-gray-700">
                                             TrackmyDoc integrates with trusted platforms like Stripe and CPACharge, enabling secure and
                                             efficient payment handling.
                                        </p>
                                   </div>
                                   <div>
                                        <h3 className="text-xl font-semibold">2. Diverse Payment Methods</h3>
                                        <p className="text-gray-700">
                                             Offer your clients multiple payment options, including credit/debit cards, Google Pay, and
                                             direct bank debit payments, ensuring convenience for every transaction.
                                        </p>
                                   </div>
                                   <div>
                                        <h3 className="text-xl font-semibold">3. Real-Time Payment Tracking</h3>
                                        <p className="text-gray-700">
                                             Monitor all incoming payments through TrackmyDoc’s dashboard. Stay updated with real-time
                                             transaction tracking and detailed reports.
                                        </p>
                                   </div>
                                   <div>
                                        <h3 className="text-xl font-semibold">4. Enhanced Client Experience</h3>
                                        <p className="text-gray-700">
                                             Provide a seamless and professional payment experience with secure, integrated payment systems
                                             that build trust and satisfaction.
                                        </p>
                                   </div>
                              </div>
                         </div>

                         <div className="mb-14">
                              <h2 className="text-2xl font-semibold mb-4">Benefits of Using TrackmyDoc for Payments</h2>
                              <ul className="list-disc list-inside space-y-2 text-gray-700">
                                   <li>
                                        <strong>Improved Cash Flow:</strong> Accelerate payment cycles with easy online payment options.
                                   </li>
                                   <li>
                                        <strong>Reduced Administrative Effort:</strong> Automate payment collection and tracking to save
                                        time.
                                   </li>
                                   <li>
                                        <strong>Enhanced Security:</strong> Protect client data with encrypted and secure payment
                                        processing.
                                   </li>
                                   <li>
                                        <strong>Client Convenience:</strong> Simplify the payment process for your clients, enhancing their
                                        overall experience.
                                   </li>
                              </ul>
                         </div>
                    </div>
               </div>
          </>
     );
}

export default PaymentProcessor;
