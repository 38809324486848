import React, { useRef } from "react";
import "./style.css";
import supportPerson from "../../assets/img/supportPerson.png";
import checkMark from "../../assets/img/Done_round.svg";

function PricingTable() {
     const tableContainerRef = useRef(null);

     // Scroll table horizontally
     //  const handleScroll = (direction) => {
     //       if (tableContainerRef.current) {
     //            const scrollAmount = 300; // Amount to scroll (in pixels)
     //            if (direction === "next") {
     //                 tableContainerRef.current.scrollLeft += scrollAmount;
     //            } else {
     //                 tableContainerRef.current.scrollLeft -= scrollAmount;
     //            }
     //       }
     //  };
     return (
          <>
               <div className="bg-[#EFF1F4] py-[120px]">
                    <div className="website-container">
                         <div className="text-[50px] font-bold leading-[40px] text-center mb-[80px] max-[496px]:text-[30px] max-[496px]:mb-[40px] relative z-10">
                              Customer service and support
                         </div>
                         <div className="pricing-table-container" ref={tableContainerRef}>
                              <table className="pricing-table">
                                   <thead>
                                        <tr>
                                             <th className="font-semibold text-[24px]">Features</th>
                                             <th className="pt-5">
                                                  {/* Sole Practitioners <br /> <span>(1 license)</span> */}
                                                  <div className="flex flex-col items-center">
                                                       <img src={supportPerson} className="mb-4" />
                                                       <div className="text-[18px] font-normal">Sole practitioners</div>
                                                       <span className="text-[#0BAFD4]">(1 license)</span>
                                                  </div>
                                             </th>
                                             <th className="pt-5">
                                                  {/* Small Firms <br /> <span>(2-9 licenses)</span> */}
                                                  <div className="flex flex-col items-center">
                                                       <img src={supportPerson} className="mb-4" />
                                                       <div className="text-[18px] font-normal">Small Firms</div>
                                                       <span className="text-[#0BAFD4]">(2-9 licenses)</span>
                                                  </div>
                                             </th>
                                             <th className="pt-5">
                                                  {/* Medium & Big Firms <br /> <span>(10-49 licenses)</span> */}
                                                  <div className="flex flex-col items-center">
                                                       <img src={supportPerson} className="mb-4" />
                                                       <div className="text-[18px] font-normal">Medium & Big Firms</div>
                                                       <span className="text-[#0BAFD4]">(10-49 licenses)</span>
                                                  </div>
                                             </th>
                                             <th className="pt-5">
                                                  {/* Enterprise <br /> <span>(50+ licenses)</span> */}
                                                  <div className="flex flex-col items-center">
                                                       <img src={supportPerson} className="mb-4" />
                                                       <div className="text-[18px] font-normal">Enterprise</div>
                                                       <span className="text-[#0BAFD4]">(50+ licenses)</span>
                                                  </div>
                                             </th>
                                        </tr>
                                   </thead>
                                   <tbody>
                                        <tr>
                                             <td className="font-normal text-[22px]">Live bootcamp workshops</td>
                                             <td>
                                                  <img src={checkMark} className="w-[40px] h-[40px] mx-auto" />
                                             </td>
                                             <td>
                                                  <img src={checkMark} className="w-[40px] h-[40px] mx-auto" />
                                             </td>
                                             <td>
                                                  <img src={checkMark} className="w-[40px] h-[40px] mx-auto" />
                                             </td>
                                             <td>{/* <img src={checkMark} className="w-[40px] h-[40px] mx-auto" /> */}</td>
                                        </tr>
                                        <tr>
                                             <td className="font-normal text-[22px]">Chat with our customer success team</td>
                                             <td>
                                                  <img src={checkMark} className="w-[40px] h-[40px] mx-auto" />
                                             </td>
                                             <td>
                                                  <img src={checkMark} className="w-[40px] h-[40px] mx-auto" />
                                             </td>
                                             <td>
                                                  <img src={checkMark} className="w-[40px] h-[40px] mx-auto" />
                                             </td>
                                             <td>{/* <img src={checkMark} className="w-[40px] h-[40px] mx-auto" /> */}</td>
                                        </tr>
                                        <tr>
                                             <td className="font-normal text-[22px]">Educational webinars</td>
                                             <td>
                                                  <img src={checkMark} className="w-[40px] h-[40px] mx-auto" />
                                             </td>
                                             <td>
                                                  <img src={checkMark} className="w-[40px] h-[40px] mx-auto" />
                                             </td>
                                             <td>
                                                  <img src={checkMark} className="w-[40px] h-[40px] mx-auto" />
                                             </td>
                                             <td>{/* <img src={checkMark} className="w-[40px] h-[40px] mx-auto" /> */}</td>
                                        </tr>
                                        <tr>
                                             <td className="font-normal text-[22px]">Help and support center</td>
                                             <td>
                                                  <img src={checkMark} className="w-[40px] h-[40px] mx-auto" />
                                             </td>
                                             <td>
                                                  <img src={checkMark} className="w-[40px] h-[40px] mx-auto" />
                                             </td>
                                             <td>
                                                  <img src={checkMark} className="w-[40px] h-[40px] mx-auto" />
                                             </td>
                                             <td>{/* <img src={checkMark} className="w-[40px] h-[40px] mx-auto" /> */}</td>
                                        </tr>
                                        <tr>
                                             <td className="font-normal text-[22px]">Trackmydoc Academy</td>
                                             <td>
                                                  <img src={checkMark} className="w-[40px] h-[40px] mx-auto" />
                                             </td>
                                             <td>
                                                  <img src={checkMark} className="w-[40px] h-[40px] mx-auto" />
                                             </td>
                                             <td>
                                                  <img src={checkMark} className="w-[40px] h-[40px] mx-auto" />
                                             </td>
                                             <td>Contact Us</td>
                                        </tr>
                                        <tr>
                                             <td className="font-normal text-[22px]">Trackmydoc Certified Advisor program</td>
                                             <td>
                                                  <img src={checkMark} className="w-[40px] h-[40px] mx-auto" />
                                             </td>
                                             <td>
                                                  <img src={checkMark} className="w-[40px] h-[40px] mx-auto" />
                                             </td>
                                             <td>
                                                  <img src={checkMark} className="w-[40px] h-[40px] mx-auto" />
                                             </td>
                                             <td></td>
                                        </tr>
                                        <tr>
                                             <td className="font-normal text-[22px]">Email and in-app support</td>
                                             <td>Same day response</td>
                                             <td>4h average response</td>
                                             <td>1h average response</td>
                                             <td></td>
                                        </tr>
                                        <tr>
                                             <td className="font-normal text-[22px]">Screen sharing sessions</td>
                                             <td>1h session included</td>
                                             <td>
                                                  <img src={checkMark} className="w-[40px] h-[40px] mx-auto" />
                                             </td>
                                             <td>
                                                  <img src={checkMark} className="w-[40px] h-[40px] mx-auto" />
                                             </td>
                                             <td></td>
                                        </tr>
                                        <tr>
                                             <td className="font-normal text-[22px]">Dedicated account manager</td>
                                             <td>
                                                  <img src={checkMark} className="w-[40px] h-[40px] mx-auto" />
                                             </td>
                                             <td>Contact Sales</td>
                                             <td>
                                                  <img src={checkMark} className="w-[40px] h-[40px] mx-auto" />
                                             </td>
                                             <td></td>
                                        </tr>
                                        <tr>
                                             <td className="font-normal text-[22px]">Custom client mobile app</td>
                                             <td className="pb-5">Contact Sales</td>
                                             <td className="pb-5">Contact Sales</td>
                                             <td className="pb-5">Contact Sales</td>
                                             <td className="pb-5">Contact Sales</td>
                                        </tr>
                                   </tbody>
                              </table>
                         </div>
                    </div>
               </div>
          </>
     );
}

export default PricingTable;
