import React, { useEffect, useState } from "react";
import "./style.css";
import { useLocation } from "react-router-dom";

function Terms() {
     const location = useLocation();
     const [selectedSection, setSelectedSection] = useState("Terms of Service");
     const sections = {
          "Terms of Service": (
               <div>
                    <div className="mb-[60px]">
                         <h2 className="text-[50px] h2-font">Terms of Service</h2>
                         <p className="text-[#7D8592] mb-4">
                              Trackmydoc provides its services ("Services") to you subject to these Terms of Service ("Terms"). By accessing
                              or using the Services, which include all software, mobile applications, websites, and related services
                              provided by Trackmydoc, you agree to be bound by these Terms, the Privacy Policy, and any other applicable
                              policies or agreements collectively referred to as the "Agreement."
                         </p>
                         <p className="text-[#7D8592]">
                              If you do not agree to the Agreement in its entirety, you may not access or use the Services. Please read
                              these Terms carefully before creating an account or using the Services, as they outline your rights,
                              responsibilities, and limitations in connection with your use of the Services.
                         </p>
                    </div>
                    <div className="space-y-14">
                         <div>
                              <h2 className="text-[36px]">1. Definitions</h2>
                              <div>
                                   <p>The accompanying definitions apply to these terms:</p>
                                   <p>
                                        «Agreement» signifies these Terms, notwithstanding our Privacy Policy, Data Processing Amendment
                                        («DPA»), Business Associate Agreement («BAA»), Refund Policy, Spam Policy, Partner Program Terms and
                                        Referral Program Terms, and any other electronic or composed agreement essentially between or
                                        pertinent to Trackmydoc and Customer administering the agreement of the Service to you, as corrected
                                        every now and then.
                                   </p>
                                   {/* Add other definitions here as needed */}
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">2. License for Use of the Service; Identity Authentication</h2>
                              <div>
                                   <p>
                                        The Service is licensed for use only under these Terms. Trackmydoc reserves all rights not expressly
                                        granted to you, including title and exclusive ownership of the Service, any and all software or
                                        updates thereto, and source code for the Service.
                                   </p>
                                   <p>
                                        Upon registering for the Service, Trackmydoc gives you the right to install the Service for use by
                                        the total number of Users you identify and authorize. The Service may not be used or accessed by
                                        unauthorized individuals or devices.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">3. Payment Processing</h2>
                              <div>
                                   <p>
                                        In order to pay your Subscription Fees through the Services, you will be required to enter your
                                        billing information, including credit card details. Payments are processed through third-party
                                        payment processors such as Stripe or CPAcharge, subject to their terms and conditions.
                                   </p>
                                   <p>
                                        Trackmydoc is not responsible for any errors or breaches occurring on the payment processor's
                                        servers. All non-financial billing information will be available to Trackmydoc for invoicing and
                                        record-keeping purposes.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">4. Term and Fees</h2>
                              <div>
                                   <p>
                                        Provided prior notice of pricing is given to you, Trackmydoc reserves the right to charge for the
                                        use of the Service on a periodic basis. These Terms shall begin upon your registration and continue
                                        until terminated by you or Trackmydoc.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">5. Other Terms</h2>
                              <div>
                                   <p>
                                        Upon the termination of these Terms, the Service and all updates may cease to function, and
                                        warranties regarding the Service shall terminate. Trackmydoc reserves the right to terminate access
                                        for any actions deemed inappropriate or unlawful.
                                   </p>
                              </div>
                         </div>
                    </div>
               </div>
          ),
          "Privacy Policy": (
               <div>
                    <div className="mb-[60px]">
                         <h2 className="text-[50px] h2-font">Privacy Policy</h2>
                         <p className="text-[#7D8592] mb-4">
                              Trackmydoc provides its services ("Services") in accordance with the following Terms of Service ("Terms").
                              These Terms are part of the overarching Agreement and define the conditions under which you may access and use
                              the Services, which include all software, mobile applications, websites, and related offerings provided by
                              Trackmydoc.
                         </p>
                         <p className="text-[#7D8592]">
                              This Security Policy establishes the principles and guidelines for ensuring the confidentiality, integrity,
                              and availability of Trackmydoc's information systems, assets, and data. By using the Services, you agree to
                              adhere to this Security Policy, the Privacy Policy, and other applicable terms and policies outlined in the
                              Agreement. If you do not agree to these terms, you are prohibited from accessing or using the Services.
                         </p>
                    </div>
                    <div className="space-y-14">
                         <div>
                              <h2 className="text-[36px]">1. Information We Collect</h2>
                              <div>
                                   <p className="text-[#7D8592]">We may collect the following types of information:</p>
                                   <ul className="list-disc pl-6 text-[#7D8592]">
                                        <li>
                                             Personal Information: Name, email address, phone number, billing address, payment information,
                                             etc.
                                        </li>
                                        <li>
                                             Non-Personal Information: Browser type, operating system, IP address, device information, and
                                             usage data.
                                        </li>
                                        <li>
                                             Cookies and Tracking Technologies: To enhance your experience, analyze site usage, and improve
                                             our services.
                                        </li>
                                   </ul>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">2. How We Use Your Information</h2>
                              <p className="text-[#7D8592]">
                                   We may use the information we collect for purposes such as providing and maintaining our services,
                                   processing transactions, sending administrative communications, improving our website, and complying with
                                   legal obligations.
                              </p>
                         </div>
                         <div>
                              <h2 className="text-[36px]">3. How We Share Your Information</h2>
                              <p className="text-[#7D8592]">
                                   We may share your information with service providers, for legal compliance, or during business transfers.
                                   We do not sell your personal information to third parties.
                              </p>
                         </div>
                         <div>
                              <h2 className="text-[36px]">4. Your Rights</h2>
                              <p className="text-[#7D8592]">
                                   Depending on your location, you may have rights to access, correct, delete, or restrict processing of
                                   your personal information. Contact us at [your email address] to exercise your rights.
                              </p>
                         </div>
                         <div>
                              <h2 className="text-[36px]">5. Data Retention</h2>
                              <p className="text-[#7D8592]">
                                   We retain your information only as long as necessary to fulfill the purposes outlined in this policy or
                                   comply with legal obligations.
                              </p>
                         </div>
                         <div>
                              <h2 className="text-[36px]">6. Data Security</h2>
                              <p className="text-[#7D8592]">
                                   We implement measures to protect your information from unauthorized access, disclosure, or misuse.
                                   However, no method of transmission over the internet is 100% secure.
                              </p>
                         </div>
                         <div>
                              <h2 className="text-[36px]">7. Changes to This Privacy Policy</h2>
                              <p className="text-[#7D8592]">
                                   We may update this Privacy Policy from time to time. Changes will be effective upon posting to this page,
                                   and the "Effective Date" will be updated accordingly.
                              </p>
                         </div>
                         {/* <div>
                              <h2 className="text-[36px]">8. Contact Us</h2>
                              <p className="text-[#7D8592]">
                                   If you have any questions or concerns about this Privacy Policy, please contact us at:
                              </p>
                              <ul className="list-disc pl-6 text-[#7D8592]">
                                   <li>Email: [your email address]</li>
                                   <li>Address: [your physical address]</li>
                              </ul>
                         </div> */}
                    </div>
               </div>
          ),
          "Security Policy": (
               <div>
                    <div className="mb-[60px]">
                         <h2 className="text-[50px] h2-font">Security Policy</h2>
                         <p className="text-[#7D8592] mb-4">
                              Trackmydoc provides the Service to you in accordance with and subject to the following Terms of Service (these
                              «Terms»). These Terms are part of the Agreement and set forth the terms and conditions under which you may use
                              the Service in accordance with the Agreement and govern your access to and use of all software, mobile apps,
                              websites and related services provided to you by Trackmydoc (collectively, the «Services»)
                         </p>
                         <p className="text-[#7D8592]">
                              Please read these Terms carefully before you begin using the Services. By using the Services or signing up for
                              an account, you accept and agree to be bound by and comply with these Terms, the Privacy Policy and other
                              applicable policies and terms of the Agreement. If you do not agree to the entire Agreement, you may not
                              access or use the Services.
                         </p>
                    </div>
                    <div className="space-y-14">
                         <div>
                              <div>
                                   <h3 className="text-[30px]">1. Purpose</h3>
                                   <p>
                                        This Security Policy outlines the framework for ensuring the confidentiality, integrity, and
                                        availability of Trackmydoc's information systems, assets, and data.
                                   </p>
                                   <h3 className="text-[30px]">2. Scope</h3>
                                   <p>
                                        This policy applies to all employees, contractors, vendors, and third parties who access or use
                                        Trackmydoc's systems, networks, or data.
                                   </p>
                                   <h3 className="text-[30px]">3. Responsibilities</h3>
                                   <p>
                                        - <strong>Executive Leadership:</strong> Ensure adequate resources for implementing and maintaining
                                        security measures.
                                        <br />- <strong>IT Department:</strong> Oversee technical controls and monitor compliance.
                                        <br />- <strong>Employees:</strong> Follow all security policies and report incidents.
                                        <br />- <strong>Third Parties:</strong> Adhere to contractual security obligations.
                                   </p>
                                   <h3 className="text-[30px]">4. Key Policies</h3>
                                   <ul className="list-disc pl-6">
                                        <li>Encrypt sensitive data at rest and in transit.</li>
                                        <li>Enforce role-based access and multi-factor authentication.</li>
                                        <li>Maintain an incident response plan for addressing security breaches.</li>
                                        <li>Provide regular security training and awareness programs.</li>
                                   </ul>
                                   <h3 className="text-[30px]">5. Reporting and Enforcement</h3>
                                   <p>
                                        All security incidents or violations must be reported to the Trackmydoc Security Team. Violations
                                        may result in disciplinary action or termination of access.
                                   </p>
                                   <h3 className="text-[30px]">6. Policy Review</h3>
                                   <p>
                                        This policy will be reviewed annually or when significant changes occur in the organization’s
                                        infrastructure or regulatory environment.
                                   </p>
                              </div>
                         </div>
                    </div>
               </div>
          ),
          "General Data Protection Regulation (GDPR)": (
               <div>
                    <div className="mb-[60px]">
                         <h2 className="text-[50px] h2-font">General Data Protection Regulation (GDPR)</h2>
                         <p className="text-[#7D8592] mb-4">
                              Trackmydoc is committed to ensuring compliance with the General Data Protection Regulation (GDPR) and other
                              relevant data protection laws. These regulations reinforce individuals' rights to control their personal data
                              and impose obligations on organizations that process such data. By accessing or using Trackmydoc's Services,
                              you confirm your understanding and acceptance of these terms, which form part of the Agreement.
                         </p>
                         <p className="text-[#7D8592]">
                              This GDPR compliance policy outlines the roles, responsibilities, and requirements for processing personal
                              data within the framework of the Agreement. If you disagree with these terms, you are prohibited from using
                              the Services.
                         </p>
                    </div>
                    <div className="space-y-14">
                         <div>
                              <h2 className="text-[36px]">1. Definitions</h2>
                              <div>
                                   <p>The accompanying definitions apply to these terms:</p>
                                   <p>
                                        «Agreement» signifies these Terms, notwithstanding our Privacy Policy, Data Processing Amendment
                                        («DPA»), Business Associate Agreement («BAA»), Refund Policy, Spam Policy, Partner Program Terms and
                                        Referral Program Terms, and any other electronic or composed agreement essentially between or
                                        pertinent to Trackmydoc and Customer administering the agreement of the Service to you, as corrected
                                        every now and then.
                                   </p>
                                   <p>
                                        «Control» signifies, for motivations behind this definition, immediate or roundabout proprietorship
                                        or control of over half of the voting interest of the subject entity.
                                   </p>
                                   <p>«Customer» signifies you or your company or employer.</p>
                                   <p>
                                        «Data Controller» signifies the regular or lawful individual, public authority, office or other body
                                        which alone or jointly with others decides the reasons and method for the Processing of Personal
                                        Data. You (or your company or employer) are the Data Controller; Trackmydoc isn’t the Data
                                        Controller but the Data Processor with respect to the Processing of your Personal Data.
                                   </p>
                                   <p>
                                        «Data Processor» signifies the entity that Processes Personal Data for the benefit of the Data
                                        Controller, including, where pertinent, a «service provider» as that term is characterized in the
                                        CCPA. Trackmydoc implies the Data Processor that has been retained by the Data Controller (you or
                                        your organization or employer) to Process your Personal Data on behalf of the Data Controller
                                        (Trackmydoc).
                                   </p>
                                   <p>
                                        «Data Protection Laws» manner any and all legal guidelines and policies, consisting of legal
                                        guidelines and policies of the European Union, the European Economic Area and their member states,
                                        Switzerland, the UK, and the USA and its states, relevant to the Processing of Personal Data below
                                        the Agreement as amended from time to time, consisting of however now no longer constrained to EU
                                        Data Protection Laws and Non-EU Data Protection Laws relevant to the Processing of Personal Data
                                        below the Agreement.
                                   </p>
                                   <p>
                                        «Fees» means the fees for provision of the Services as described on the Trackmydoc Website or
                                        otherwise set out in our Portal, including but not limited to Subscription Fees, Additional Client
                                        Fees, Payment Service Fees (as applicable).
                                   </p>
                                   <p>
                                        «Personal Data» means any information relating to (i) an identified or identifiable natural person
                                        and, (ii) an identified or identifiable legal entity (where such information is protected similarly
                                        as personal data or personally identifiable information under applicable Data Protection Laws).
                                   </p>
                                   <p>
                                        «Process» and «Processing» means any operation or set of operations which is performed upon Personal
                                        Data, whether or not by automatic means, including collection, recording, organization, structuring,
                                        storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission,
                                        dissemination or otherwise making available, alignment or combination, restriction, erasure or
                                        destruction.
                                   </p>
                                   <p>
                                        «Payment Service Fees» means the service fee for the processing of payments as described on the
                                        Trackmydoc Website or otherwise set out in your Portal. «Proposal» refers to a proposal for
                                        accounting or other professional services (“Professional Services”) that a Logged-In User has
                                        created through the Services to send to a Client.
                                   </p>
                                   <p>
                                        «Service» means the online tax firm management services made available (as may be changed or updated
                                        from time to time by Trackmydoc) via the website.
                                   </p>
                                   <p>
                                        «Website» means the Internet site at the domain www.taxdome.com or any other site operated by
                                        Trackmydoc.
                                   </p>
                                   <p>
                                        «Trackmydoc» means Trackmydoc, Llc. and all current and future global subsidiaries of Trackmydoc
                                        Llc.
                                   </p>
                                   <p>«TOS» Terms of Service</p>
                                   <p>«You», «Your», or «Customer» refers to anyone accessing or subject to the Service.</p>
                                   <p>
                                        «User» refers to the individual who has created an account to use the Services for their own benefit
                                        and / or on behalf of a company, partnership, association or other legal entity (a “Legal Entity”).
                                        When a User has created an account to use the Services and is logged in, they may be referred to in
                                        these TOS as a “Logged-in User”. The Legal Entity that the User is representing may be referred to
                                        in these TOS as a “Professional Services Provider”.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">2. License for Use of the Service; Identity Authentication</h2>
                              <div>
                                   <p>
                                        The Service is licensed for use only under these Terms. Trackmydoc reserves all rights not expressly
                                        granted to you, including title and exclusive ownership of the Service, any and all software or
                                        updates thereto, and source code for the Service.
                                   </p>
                                   <p>
                                        Upon registering for the Service, Trackmydoc gives you the right to install the Service for use by
                                        the total number of Users you identify and authorize. The Service may not be used or accessed by (a)
                                        individuals who are not named individuals; or (b) any other software or hardware device that does
                                        not require a named individual to use or access it. A named individual means an individual
                                        identified by you by name who is authorized to use the Service, regardless of how such access occurs
                                        or if such individual uses any hardware or software that reduces the apparent number of Users who
                                        are using the Service, such as by using a terminal service. The Service may not be used or accessed
                                        by any other software or hardware device that does not require an individual to use or access it.
                                        Trackmydoc reserves the right at any time to require you to provide a list of the named
                                        individual(s).
                                   </p>
                                   <p>
                                        You may not rent, lease, lend, sell, redistribute or sublease the Service. These Terms will govern
                                        any upgrades provided by Trackmydoc that replace and/or supplement the original Service. You agree
                                        to use your best efforts to protect the Service and upgrades from unauthorized use, reproduction,
                                        distribution, publication, or alteration.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">3. Payment Processing</h2>
                              <div>
                                   <p>
                                        In order to pay your Subscription Fees through the Services, you will be required to enter your
                                        first name, last name, email, phone number, billing address, credit card number, credit card expiry
                                        date, and card security code (CVC). This may be referred to in these TOS or the Privacy Policy as
                                        “Billing Information”.
                                   </p>
                                   <p>
                                        All payments are made using a secure https:// connection, and payment processing is handled through
                                        the “Third-Party Payment Processor” Stripe or CPAcharge, though this is subject to change without
                                        notice. The Third-Party Payment Processor currently accepts certain credit cards as payment options
                                        but these are subject to change without notice. Once transactions are accepted by the Third-Party
                                        Payment Processor, they are processed in accordance with their program rules and procedures and
                                        Terms of Service. Trackmydoc and the Third-Party Payment Processor are unaffiliated companies and
                                        Trackmydoc has no influence on the operations of the Third-Party Payment Processor. Trackmydoc and /
                                        or its Representatives shall in no way be held responsible for any losses or damages, direct or
                                        indirect, pecuniary or otherwise, resulting from any error or failure on the part of the Third-Party
                                        Payment Processor.
                                   </p>
                                   <p>
                                        All Billing Information is collected by the Third-Party Payment Processor, on their own secured
                                        servers. Trackmydoc does not have access to any credit card information, nor can it be responsible
                                        for any breach of information caused by faulty programming or malicious users on the servers of the
                                        Third-Party Payment Processor. Non-financial information will, however, be available to Trackmydoc
                                        for invoice-making and record-keeping purposes.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">4. Term and Fees</h2>
                              <div>
                                   <p>
                                        Provided prior notice of pricing is given to you, Trackmydoc reserves the right to charge you for
                                        use of the Service on a periodic (e.g., weekly or monthly) basis or otherwise. These Terms shall
                                        begin upon your registration for the Services and shall continue until your use of the Service is
                                        terminated by you or by Trackmydoc. You are responsible for all fees due to Trackmydoc and any
                                        compensation due prior to the termination date.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">5. Other Terms</h2>
                              <div>
                                   <p>
                                        Upon the termination of these Terms, the Service and all updates may cease to properly function, and
                                        all warranties, express or implied, regarding the Service shall terminate. Your rights under these
                                        terms will automatically terminate without notice from Trackmydoc if you fail to comply with any
                                        provision of these Terms. Further, Trackmydoc may terminate the Service for any action taken by you
                                        that Trackmydoc believes in its sole discretion is an inappropriate use of the Service even if not
                                        specifically detailed by these Terms, including any use of the Service that is prohibited by
                                        federal, state, or local law. Any warranty regarding the Service will automatically terminate
                                        without notice if you fail to comply with any provision of these Terms. The parties expressly
                                        acknowledge and agree that all provisions of these Terms that concern Copyright or other protectable
                                        interests of Trackmydoc shall remain in full force and effect notwithstanding termination of any
                                        warranty or use of the Service.
                                   </p>
                              </div>
                         </div>
                    </div>
               </div>
          ),
          "Data Processing Amendment": (
               <div>
                    <div className="mb-[60px]">
                         <h2 className="text-[50px] h2-font">Data Processing Amendment</h2>
                         <p className="text-[#7D8592] mb-4">
                              Trackmydoc is dedicated to safeguarding the confidentiality, integrity, and security of personal data
                              processed through its Services. This Data Processing Amendment (DPA) outlines the roles, obligations, and
                              responsibilities of both Trackmydoc and the Customer under applicable Data Protection Laws. By using the
                              Services, you acknowledge and agree to the terms outlined in this DPA, which forms an integral part of the
                              Agreement.
                         </p>
                         <p className="text-[#7D8592]">
                              If you do not agree with this DPA or any part of the Agreement, you are prohibited from accessing or using the
                              Services.
                         </p>
                    </div>
                    <div className="space-y-14">
                         <div>
                              <h2 className="text-[36px]">1. Definitions</h2>
                              <div>
                                   <p>The accompanying definitions apply to these terms:</p>
                                   <p>
                                        «Agreement» signifies these Terms, notwithstanding our Privacy Policy, Data Processing Amendment
                                        («DPA»), Business Associate Agreement («BAA»), Refund Policy, Spam Policy, Partner Program Terms and
                                        Referral Program Terms, and any other electronic or composed agreement essentially between or
                                        pertinent to Trackmydoc and Customer administering the agreement of the Service to you, as corrected
                                        every now and then.
                                   </p>
                                   <p>
                                        «Control» signifies, for motivations behind this definition, immediate or roundabout proprietorship
                                        or control of over half of the voting interest of the subject entity.
                                   </p>
                                   <p>«Customer» signifies you or your company or employer.</p>
                                   <p>
                                        «Data Controller» signifies the regular or lawful individual, public authority, office or other body
                                        which alone or jointly with others decides the reasons and method for the Processing of Personal
                                        Data. You (or your company or employer) is the Data Controller; Trackmydoc isn’t the Data Controller
                                        but the Data Processor with respect to the Processing of your Personal Data.
                                   </p>
                                   <p>
                                        «Data Processor» signifies the entity that Processes Personal Data for the benefit of the Data
                                        Controller, including, where pertinent, a «service provider» as that term is characterized in the
                                        CCPA. Trackmydoc implies the Data Processor that has been retained by the Data Controller (you or
                                        your organization or employer) to Process your Personal Data on behalf of the Data Controller
                                        (Trackmydoc).
                                   </p>
                                   <p>
                                        «Data Protection Laws» manner any and all legal guidelines and policies, consisting of legal
                                        guidelines and policies of the European Union, the European Economic Area and their member states,
                                        Switzerland, the UK, and the USA and its states, relevant to the Processing of Personal Data below
                                        the Agreement as amended from time to time, consisting of however now no longer constrained to EU
                                        Data Protection Laws and Non-EU Data Protection Laws relevant to the Processing of Personal Data
                                        below the Agreement.
                                   </p>
                                   <p>
                                        «Fees» means the fees for provision of the Services as described on the Trackmydoc Website or
                                        otherwise set out in our Portal, including but not limited to Subscription Fees, Additional Client
                                        Fees, Payment Service Fees (as applicable).
                                   </p>
                                   <p>
                                        «Personal Data» means any information relating to (i) an identified or identifiable natural person
                                        and, (ii) an identified or identifiable legal entity (where such information is protected similarly
                                        as personal data or personally identifiable information under applicable Data Protection Laws).
                                   </p>
                                   <p>
                                        «Process» and «Processing» means any operation or set of operations which is performed upon Personal
                                        Data, whether or not by automatic means, including collection, recording, organization, structuring,
                                        storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission,
                                        dissemination or otherwise making available, alignment or combination, restriction, erasure or
                                        destruction.
                                   </p>
                                   <p>
                                        «Payment Service Fees» means the service fee for the processing of payments as described on the
                                        Trackmydoc Website or otherwise set out in your Portal. «Proposal» refers to a proposal for
                                        accounting or other professional services (“Professional Services”) that a Logged-In User has
                                        created through the Services to send to a Client.
                                   </p>
                                   <p>
                                        «Service» means the online tax firm management services made available (as may be changed or updated
                                        from time to time by Trackmydoc) via the website.
                                   </p>
                                   <p>
                                        «Website» means the Internet site at the domain www.taxdome.com or any other site operated by
                                        Trackmydoc.
                                   </p>
                                   <p>
                                        «Trackmydoc» means Trackmydoc, Llc. and all current and future global subsidiaries of Trackmydoc
                                        Llc.
                                   </p>
                                   <p>«TOS» Terms of Service</p>
                                   <p>«You»,«Your», or «Customer» refers to anyone accessing or subject to the Service.</p>
                                   <p>
                                        «User» refers to the individual who has created an account to use the Services for their own benefit
                                        and / or on behalf of a company, partnership, association or other legal entity (a “Legal Entity”).
                                        When a User has created an account to use the Services and is logged in, they may be referred to in
                                        these TOS as a “Logged-in User”. The Legal Entity that the User is representing may be referred to
                                        in these TOS as a “Professional Services Provider”.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">2. License for Use of the Service; Identity Authentication</h2>
                              <div>
                                   <p>
                                        The Service is licensed for use only under these Terms. Trackmydoc reserves all rights not expressly
                                        granted to you, including title and exclusive ownership of the Service, any and all software or
                                        updates thereto, and source code for the Service.
                                   </p>
                                   <p>
                                        Upon registering for the Service, Trackmydoc gives you the right to install the Service for use by
                                        the total number of Users you identify and authorize. The Service may not be used or accessed by (a)
                                        individuals who are not named individuals; or (b) any other software or hardware device that does
                                        not require a named individual to use or access it. A named individual means an individual
                                        identified by you by name who is authorized to use the Service, regardless of how such access occurs
                                        or if such individual uses any hardware or software that reduces the apparent number of Users who
                                        are using the Service, such as by using a terminal service. The Service may not be used or accessed
                                        by any other software or hardware device that does not require an individual to use or access it.
                                        Trackmydoc reserves the right at any time to require you to provide a list of the named
                                        individual(s).
                                   </p>
                                   <p>
                                        You may not rent, lease, lend, sell, redistribute or sublease the Service. These Terms will govern
                                        any upgrades provided by Trackmydoc that replace and/or supplement the original Service. You agree
                                        to use your best efforts to protect the Service and upgrades from unauthorized use, reproduction,
                                        distribution, publication, or alteration.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">3. Payment Processing</h2>
                              <div>
                                   <p>
                                        In order to pay your Subscription Fees through the Services, you will be required to enter your
                                        first name, last name, email, phone number, billing address, credit card number, credit card expiry
                                        date, and card security code (CVC). This may be referred to in these TOS or the Privacy Policy as
                                        “Billing Information”.
                                   </p>
                                   <p>
                                        All payments are made using a secure https:// connection, and payment processing is handled through
                                        the “Third-Party Payment Processor” Stripe or CPAcharge, though this is subject to change without
                                        notice. The Third-Party Payment Processor currently accepts certain credit cards as payment options
                                        but these are subject to change without notice. Once transactions are accepted by the Third-Party
                                        Payment Processor, they are processed in accordance with their program rules and procedures and
                                        Terms of Service. Trackmydoc and the Third-Party Payment Processor are unaffiliated companies and
                                        Trackmydoc has no influence on the operations of the Third-Party Payment Processor. Trackmydoc
                                        and/or its Representatives shall in no way be held responsible for any losses or damages, direct or
                                        indirect, pecuniary or otherwise, resulting from any error or failure on the part of the Third-Party
                                        Payment Processor.
                                   </p>
                                   <p>
                                        All Billing Information is collected by the Third-Party Payment Processor, on their own secured
                                        servers. Trackmydoc does not have access to any credit card information, nor can it be responsible
                                        for any breach of information caused by faulty programming or malicious users on the servers of the
                                        Third-Party Payment Processor. Non-financial information will, however, be available to Trackmydoc
                                        for invoice-making and record-keeping purposes.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">4. Term and Fees</h2>
                              <div>
                                   <p>
                                        Provided prior notice of pricing is given to you, Trackmydoc reserves the right to charge you for
                                        use of the Service on a periodic (e.g., weekly or monthly) basis or otherwise. These Terms shall
                                        begin upon your registration for the Services and shall continue until your use of the Service is
                                        terminated by you or by Trackmydoc. You are responsible for all fees due to Trackmydoc and any
                                        compensation due prior to the termination date.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">5. Other Terms</h2>
                              <div>
                                   <p>
                                        Upon the termination of these Terms, the Service and all updates may cease to properly function, and
                                        all warranties, express or implied, regarding the Service shall terminate. Your rights under these
                                        terms will automatically terminate without notice from Trackmydoc if you fail to comply with any
                                        provision of these Terms. Further, Trackmydoc may terminate the Service for any action taken by you
                                        that Trackmydoc believes in its sole discretion is an inappropriate use of the Service even if not
                                        specifically detailed by these Terms, including any use of the Service that is prohibited by
                                        federal, state, or local law. Any warranty regarding the Service will automatically terminate
                                        without notice if you fail to comply with any provision of these Terms. The parties expressly
                                        acknowledge and agree that all provisions of these Terms that concern Copyright or other protectable
                                        interests of Trackmydoc shall remain in full force and effect notwithstanding termination of any
                                        warranty or use of the Service.
                                   </p>
                              </div>
                         </div>
                    </div>
               </div>
          ),
          "Cookie Policy": (
               <div>
                    <div className="mb-[60px]">
                         <h2 className="text-[50px] h2-font">Cookie Policy</h2>
                         <p className="text-[#7D8592] mb-4">
                              This Cookie Policy outlines how Trackmydoc uses cookies and similar technologies to recognize and interact
                              with you when you visit our website, use our mobile application, or access our services (collectively referred
                              to as the "Services"). It explains what these technologies are, why we use them, and how you can manage your
                              preferences regarding their use.
                         </p>
                    </div>
                    <div className="space-y-14">
                         <div>
                              <h2 className="text-[36px]">1. Definitions</h2>
                              <div>
                                   <p>The accompanying definitions apply to these terms:</p>
                                   <p>
                                        «Agreement» signifies these Terms, notwithstanding our Privacy Policy, Data Processing Amendment
                                        («DPA»), Business Associate Agreement («BAA»), Refund Policy, Spam Policy, Partner Program Terms and
                                        Referral Program Terms, and any other electronic or composed agreement essentially between or
                                        pertinent to Trackmydoc and Customer administering the agreement of the Service to you, as corrected
                                        every now and then.
                                   </p>
                                   <p>
                                        «Control» signifies, for motivations behind this definition, immediate or roundabout proprietorship
                                        or control of over half of the voting interest of the subject entity.
                                   </p>
                                   <p>«Customer» signifies you or your company or employer.</p>
                                   <p>
                                        «Data Controller» signifies the regular or lawful individual, public authority, office or other body
                                        which alone or jointly with others decides the reasons and method for the Processing of Personal
                                        Data. You (or your company or employer) is the Data Controller; Trackmydoc isn’t the Data Controller
                                        but the Data Processor with respect to the Processing of your Personal Data.
                                   </p>
                                   <p>
                                        «Data Processor» signifies the entity that Processes Personal Data for the benefit of the Data
                                        Controller, including, where pertinent, a «service provider» as that term is characterized in the
                                        CCPA. Trackmydoc implies the Data Processor that has been retained by the Data Controller (you or
                                        your organization or employer) to Process your Personal Data on behalf of the Data Controller
                                        (Trackmydoc).
                                   </p>
                                   <p>
                                        «Data Protection Laws» manner any and all legal guidelines and policies, consisting of legal
                                        guidelines and policies of the European Union, the European Economic Area and their member states,
                                        Switzerland, the UK, and the USA and its states, relevant to the Processing of Personal Data below
                                        the Agreement as amended from time to time, consisting of however now no longer constrained to EU
                                        Data Protection Laws and Non-EU Data Protection Laws relevant to the Processing of Personal Data
                                        below the Agreement.
                                   </p>
                                   <p>
                                        «Fees» means the fees for provision of the Services as described on the Trackmydoc Website or
                                        otherwise set out in our Portal, including but not limited to Subscription Fees, Additional Client
                                        Fees, Payment Service Fees (as applicable).
                                   </p>
                                   <p>
                                        «Personal Data» means any information relating to (i) an identified or identifiable natural person
                                        and, (ii) an identified or identifiable legal entity (where such information is protected similarly
                                        as personal data or personally identifiable information under applicable Data Protection Laws).
                                   </p>
                                   <p>
                                        «Process» and «Processing» means any operation or set of operations which is performed upon Personal
                                        Data, whether or not by automatic means, including collection, recording, organization, structuring,
                                        storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission,
                                        dissemination or otherwise making available, alignment or combination, restriction, erasure or
                                        destruction.
                                   </p>
                                   <p>
                                        «Payment Service Fees» means the service fee for the processing of payments as described on the
                                        Trackmydoc Website or otherwise set out in your Portal. «Proposal» refers to a proposal for
                                        accounting or other professional services (“Professional Services”) that a Logged-In User has
                                        created through the Services to send to a Client.
                                   </p>
                                   <p>
                                        «Service» means the online tax firm management services made available (as may be changed or updated
                                        from time to time by Trackmydoc) via the website.
                                   </p>
                                   <p>
                                        «Website» means the Internet site at the domain www.taxdome.com or any other site operated by
                                        Trackmydoc.
                                   </p>
                                   <p>
                                        «Trackmydoc» means Trackmydoc, Llc. and all current and future global subsidiaries of Trackmydoc
                                        Llc.
                                   </p>
                                   <p>«TOS» Terms of Service</p>
                                   <p>«You», «Your», or «Customer» refers to anyone accessing or subject to the Service.</p>
                                   <p>
                                        «User» refers to the individual who has created an account to use the Services for their own benefit
                                        and / or on behalf of a company, partnership, association or other legal entity (a “Legal Entity”).
                                        When a User has created an account to use the Services and is logged in, they may be referred to in
                                        these TOS as a “Logged-in User”. The Legal Entity that the User is representing may be referred to
                                        in these TOS as a “Professional Services Provider”.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">2. License for Use of the Service; Identity Authentication</h2>
                              <div>
                                   <p>
                                        The Service is licensed for use only under these Terms. Trackmydoc reserves all rights not expressly
                                        granted to you, including title and exclusive ownership of the Service, any and all software or
                                        updates thereto, and source code for the Service.
                                   </p>
                                   <p>
                                        Upon registering for the Service, Trackmydoc gives you the right to install the Service for use by
                                        the total number of Users you identify and authorize. The Service may not be used or accessed by (a)
                                        individuals who are not named individuals; or (b) any other software or hardware device that does
                                        not require a named individual to use or access it. A named individual means an individual
                                        identified by you by name who is authorized to use the Service, regardless of how such access occurs
                                        or if such individual uses any hardware or software that reduces the apparent number of Users who
                                        are using the Service, such as by using a terminal service. The Service may not be used or accessed
                                        by any other software or hardware device that does not require an individual to use or access it.
                                        Trackmydoc reserves the right at any time to require you to provide a list of the named
                                        individual(s).
                                   </p>
                                   <p>
                                        You may not rent, lease, lend, sell, redistribute or sublease the Service. These Terms will govern
                                        any upgrades provided by Trackmydoc that replace and/or supplement the original Service. You agree
                                        to use your best efforts to protect the Service and upgrades from unauthorized use, reproduction,
                                        distribution, publication, or alteration.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">3. Payment Processing</h2>
                              <div>
                                   <p>
                                        In order to pay your Subscription Fees through the Services, you will be required to enter your
                                        first name, last name, email, phone number, billing address, credit card number, credit card expiry
                                        date, and card security code (CVC). This may be referred to in these TOS or the Privacy Policy as
                                        “Billing Information”.
                                   </p>
                                   <p>
                                        All payments are made using a secure https:// connection, and payment processing is handled through
                                        the “Third-Party Payment Processor” Stripe or CPAcharge, though this is subject to change without
                                        notice. The Third-Party Payment Processor currently accepts certain credit cards as payment options
                                        but these are subject to change without notice. Once transactions are accepted by the Third-Party
                                        Payment Processor, they are processed in accordance with their program rules and procedures and
                                        Terms of Service. Trackmydoc and the Third-Party Payment Processor are unaffiliated companies and
                                        Trackmydoc has no influence on the operations of the Third-Party Payment Processor. Trackmydoc
                                        and/or its Representatives shall in no way be held responsible for any losses or damages, direct or
                                        indirect, pecuniary or otherwise, resulting from any error or failure on the part of the Third-Party
                                        Payment Processor.
                                   </p>
                                   <p>
                                        All Billing Information is collected by the Third-Party Payment Processor, on their own secured
                                        servers. Trackmydoc does not have access to any credit card information, nor can it be responsible
                                        for any breach of information caused by faulty programming or malicious users on the servers of the
                                        Third-Party Payment Processor. Non-financial information will, however, be available to Trackmydoc
                                        for invoice-making and record-keeping purposes.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">4. Term and Fees</h2>
                              <div>
                                   <p>
                                        Provided prior notice of pricing is given to you, Trackmydoc reserves the right to charge you for
                                        use of the Service on a periodic (e.g., weekly or monthly) basis or otherwise. These Terms shall
                                        begin upon your registration for the Services and shall continue until your use of the Service is
                                        terminated by you or by Trackmydoc. You are responsible for all fees due to Trackmydoc and any
                                        compensation due prior to the termination date.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">5. Other Terms</h2>
                              <div>
                                   <p>
                                        Upon the termination of these Terms, the Service and all updates may cease to properly function, and
                                        all warranties, express or implied, regarding the Service shall terminate. Your rights under these
                                        terms will automatically terminate without notice from Trackmydoc if you fail to comply with any
                                        provision of these Terms. Further, Trackmydoc may terminate the Service for any action taken by you
                                        that Trackmydoc believes in its sole discretion is an inappropriate use of the Service even if not
                                        specifically detailed by these Terms, including any use of the Service that is prohibited by
                                        federal, state, or local law. Any warranty regarding the Service will automatically terminate
                                        without notice if you fail to comply with any provision of these Terms. The parties expressly
                                        acknowledge and agree that all provisions of these Terms that concern Copyright or other protectable
                                        interests of Trackmydoc shall remain in full force and effect notwithstanding termination of any
                                        warranty or use of the Service.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">6. Cookie Policy</h2>
                              <div>
                                   <p>
                                        This Cookie Policy explains how Trackmydoc uses cookies and similar technologies to recognize you
                                        when you visit our website, use our mobile application, or interact with our services (collectively,
                                        the "Services"). It explains what these technologies are, why we use them, and your rights to
                                        control their use.
                                   </p>
                                   <p>
                                        <strong>What are Cookies?</strong>
                                   </p>
                                   <p>
                                        Cookies are small text files that are stored on your device when you visit a website. They help the
                                        website remember your actions and preferences over time. Cookies may also be used to improve user
                                        experience, analyze website traffic, and deliver targeted advertising.
                                   </p>
                                   <p>
                                        <strong>How We Use Cookies</strong>
                                   </p>
                                   <p>
                                        We use cookies for several reasons, including:
                                        <ul>
                                             <li>
                                                  <strong>Essential Cookies:</strong> Necessary for the website to function properly.
                                             </li>
                                             <li>
                                                  <strong>Performance Cookies:</strong> Help us understand website usage and improve
                                                  performance.
                                             </li>
                                             <li>
                                                  <strong>Functionality Cookies:</strong> Enable enhanced functionality and personalization.
                                             </li>
                                             <li>
                                                  <strong>Targeting Cookies:</strong> Used for delivering targeted advertising.
                                             </li>
                                        </ul>
                                   </p>
                                   <p>
                                        <strong>Managing Cookies</strong>
                                   </p>
                                   <p>
                                        You have the right to control and manage cookies. You can adjust your browser settings to block or
                                        delete cookies. However, please note that disabling cookies may affect your experience on our
                                        website and some features may not work as intended.
                                   </p>
                                   <p>
                                        <strong>Third-Party Cookies</strong>
                                   </p>
                                   <p>
                                        We may allow third-party service providers to use cookies on our website for services such as
                                        analytics, advertising, or social media integration.
                                   </p>
                                   <p>
                                        <strong>Changes to This Cookie Policy</strong>
                                   </p>
                                   <p>
                                        We may update this Cookie Policy from time to time. Any changes will be posted on this page, and the
                                        "Effective Date" will be updated accordingly.
                                   </p>
                              </div>
                         </div>
                    </div>
               </div>
          ),
          "List of Sub-processors": (
               <div>
                    <div className="mb-[60px]">
                         <h2 className="text-[50px] h2-font">List of Sub-processors</h2>
                         <p className="text-[#7D8592] mb-4">
                              This document outlines the sub-processors engaged by Trackmydoc to process personal data in connection with
                              the services provided. As part of our commitment to data protection, we ensure that all sub-processors comply
                              with applicable data protection laws and regulations.
                         </p>
                    </div>
                    <div className="space-y-14">
                         <div>
                              <h2 className="text-[36px]">1. Sub-Processor List</h2>
                              <div>
                                   <p>
                                        This document outlines the sub-processors that Trackmydoc engages with to process personal data in
                                        connection with the services provided. As part of our commitment to data protection, we ensure that
                                        all sub-processors comply with the applicable data protection laws and regulations.
                                   </p>
                                   <ul className="list-disc pl-6 mt-4">
                                        <li>
                                             <strong>Sub-Processor 1:</strong> <br />
                                             Name: Demo Sub-Processor Inc.
                                             <br />
                                             Service: Cloud Hosting
                                             <br />
                                             Purpose: Data storage and processing for user data.
                                             <br />
                                             Location: United States
                                             <br />
                                             Compliance: GDPR, CCPA
                                        </li>
                                        <li>
                                             <strong>Sub-Processor 2:</strong> <br />
                                             Name: Demo Payment Processor Ltd.
                                             <br />
                                             Service: Payment processing
                                             <br />
                                             Purpose: Handling subscription and transaction payments.
                                             <br />
                                             Location: United Kingdom
                                             <br />
                                             Compliance: PCI-DSS, GDPR
                                        </li>
                                        <li>
                                             <strong>Sub-Processor 3:</strong> <br />
                                             Name: Demo Analytics Services LLC.
                                             <br />
                                             Service: Web analytics
                                             <br />
                                             Purpose: Analyzing user behavior on the website.
                                             <br />
                                             Location: Germany
                                             <br />
                                             Compliance: GDPR
                                        </li>
                                        <li>
                                             <strong>Sub-Processor 4:</strong> <br />
                                             Name: Demo Email Services Corp.
                                             <br />
                                             Service: Email marketing
                                             <br />
                                             Purpose: Sending newsletters and promotional emails.
                                             <br />
                                             Location: Canada
                                             <br />
                                             Compliance: GDPR, CASL
                                        </li>
                                   </ul>
                                   <p className="mt-4">
                                        We will notify our customers about any changes to this list, including the addition of new
                                        sub-processors or changes to existing sub-processors, in accordance with the terms of our data
                                        processing agreements.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">2. Sub-Processor Updates</h2>
                              <p>
                                   We will notify our customers about any changes to this list, including the addition of new sub-processors
                                   or changes to existing sub-processors, in accordance with the terms of our data processing agreements.
                              </p>
                         </div>
                         <div>
                              <h2 className="text-[36px]">3. Data Processing Agreement</h2>
                              <p>
                                   All sub-processors are required to sign a data processing agreement (DPA) that ensures compliance with
                                   relevant data protection laws, including GDPR (if applicable), and outlines the sub-processor’s
                                   responsibilities for handling personal data.
                              </p>
                         </div>
                         <div>
                              <h2 className="text-[36px]">4. Contact Information</h2>
                              <p>
                                   If you have any questions regarding the sub-processor list or data processing practices, please contact
                                   us.
                              </p>
                         </div>
                    </div>
               </div>
          ),
          "Accessibility Statement": (
               <div>
                    <div className="mb-[60px]">
                         <h2 className="text-[50px] h2-font">Accessibility Statement</h2>
                         <p className="text-[#7D8592] mb-4">
                              At Trackmydoc, we are committed to ensuring that our website and services are accessible to everyone,
                              including individuals with disabilities. We strive to provide a user-friendly experience for all of our
                              visitors, and we are continuously working to improve the accessibility of our site.
                         </p>
                         <p className="text-[#7D8592] mb-4">
                              Our website has been designed with accessibility in mind, and we have taken the following steps to enhance the
                              accessibility of our services:
                         </p>
                         <ul className="text-[#7D8592] list-disc pl-6 mb-4">
                              <li>
                                   <strong>Keyboard Navigation:</strong> All interactive elements on our site can be accessed and navigated
                                   using a keyboard.
                              </li>
                              <li>
                                   <strong>Screen Reader Compatibility:</strong> We ensure that our website is compatible with screen
                                   readers and other assistive technologies.
                              </li>
                              <li>
                                   <strong>Color Contrast:</strong> We have used high contrast colors to ensure that text is readable for
                                   individuals with visual impairments.
                              </li>
                              <li>
                                   <strong>Text Alternatives:</strong> All images and non-text content on the site include descriptive alt
                                   text for screen readers.
                              </li>
                              <li>
                                   <strong>Resizable Text:</strong> Our website allows users to adjust the size of the text to suit their
                                   needs.
                              </li>
                              <li>
                                   <strong>Accessible Forms:</strong> Forms on our website include clear labels and instructions to assist
                                   users in completing them.
                              </li>
                         </ul>
                         <p className="text-[#7D8592] mb-4">
                              We are committed to maintaining and improving the accessibility of our website. We regularly test our website
                              for compliance with the Web Content Accessibility Guidelines (WCAG) 2.1 and other relevant accessibility
                              standards. We also welcome feedback from our users to help us identify areas for improvement.
                         </p>
                         <p className="text-[#7D8592] mb-4">
                              If you experience any difficulty accessing our website or have any questions about the accessibility features,
                              please feel free to contact us. We value your feedback and will do our best to address any issues you may
                              encounter.
                         </p>
                    </div>
                    <div className="space-y-14">
                         <div>
                              <h2 className="text-[36px]">1. Definitions</h2>
                              <div>
                                   <p>The accompanying definitions apply to these terms:</p>
                                   <p>
                                        «Agreement» signifies these Terms, notwithstanding our Privacy Policy, Data Processing Amendment
                                        («DPA»), Business Associate Agreement («BAA»), Refund Policy, Spam Policy, Partner Program Terms and
                                        Referral Program Terms, and any other electronic or composed agreement essentially between or
                                        pertinent to Trackmydoc and Customer administering the agreement of the Service to you, as corrected
                                        every now and then.
                                   </p>
                                   <p>
                                        «Control» signifies, for motivations behind this definition, immediate or roundabout proprietorship
                                        or control of over half of the voting interest of the subject entity.
                                   </p>
                                   <p>«Customer» signifies you or your company or employer.</p>
                                   <p>
                                        «Data Controller» signifies the regular or lawful individual, public authority, office or other body
                                        which alone or jointly with others decides the reasons and method for the Processing of Personal
                                        Data. You (or your company or employer) is the Data Controller; Trackmydoc isn’t the Data Controller
                                        but the Data Processor with respect to the Processing of your Personal Data.
                                   </p>
                                   <p>
                                        «Data Processor» signifies the entity that Processes Personal Data for the benefit of the Data
                                        Controller, including, where pertinent, a «service provider» as that term is characterized in the
                                        CCPA. Trackmydoc implies the Data Processor that has been retained by the Data Controller (you or
                                        your organization or employer) to Process your Personal Data on behalf of the Data Controller
                                        (Trackmydoc).
                                   </p>
                                   <p>
                                        «Data Protection Laws» manner any and all legal guidelines and policies, consisting of legal
                                        guidelines and policies of the European Union, the European Economic Area and their member states,
                                        Switzerland, the UK, and the USA and its states, relevant to the Processing of Personal Data below
                                        the Agreement as amended from time to time, consisting of however now no longer constrained to EU
                                        Data Protection Laws and Non-EU Data Protection Laws relevant to the Processing of Personal Data
                                        below the Agreement.
                                   </p>
                                   <p>
                                        «Fees» means the fees for provision of the Services as described on the Trackmydoc Website or
                                        otherwise set out in our Portal, including but not limited to Subscription Fees, Additional Client
                                        Fees, Payment Service Fees (as applicable).
                                   </p>
                                   <p>
                                        «Personal Data» means any information relating to (i) an identified or identifiable natural person
                                        and, (ii) an identified or identifiable legal entity (where such information is protected similarly
                                        as personal data or personally identifiable information under applicable Data Protection Laws).
                                   </p>
                                   <p>
                                        «Process» and «Processing» means any operation or set of operations which is performed upon Personal
                                        Data, whether or not by automatic means, including collection, recording, organization, structuring,
                                        storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission,
                                        dissemination or otherwise making available, alignment or combination, restriction, erasure or
                                        destruction.
                                   </p>
                                   <p>
                                        «Payment Service Fees» means the service fee for the processing of payments as described on the
                                        Trackmydoc Website or otherwise set out in your Portal. «Proposal» refers to a proposal for
                                        accounting or other professional services (“Professional Services”) that a Logged-In User has
                                        created through the Services to send to a Client.
                                   </p>
                                   <p>
                                        «Service» means the online tax firm management services made available (as may be changed or updated
                                        from time to time by Trackmydoc) via the website.
                                   </p>
                                   <p>
                                        «Website» means the Internet site at the domain www.taxdome.com or any other site operated by
                                        Trackmydoc.
                                   </p>
                                   <p>
                                        «Trackmydoc» means Trackmydoc, Llc. and all current and future global subsidiaries of Trackmydoc
                                        Llc.
                                   </p>
                                   <p>«TOS» Terms of Service</p>
                                   <p>«You»,«Your», or «Customer» refers to anyone accessing or subject to the Service.</p>
                                   <p>
                                        «User» refers to the individual who has created an account to use the Services for their own benefit
                                        and / or on behalf of a company, partnership, association or other legal entity (a “Legal Entity”).
                                        When a User has created an account to use the Services and is logged in, they may be referred to in
                                        these TOS as a “Logged-in User”. The Legal Entity that the User is representing may be referred to
                                        in these TOS as a “Professional Services Provider”.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">2. License for Use of the Service; Identity Authentication</h2>
                              <div>
                                   <p>
                                        The Service is licensed for use only under these Terms. Trackmydoc reserves all rights not expressly
                                        granted to you, including title and exclusive ownership of the Service, any and all software or
                                        updates thereto, and source code for the Service.
                                   </p>
                                   <p>
                                        Upon registering for the Service, Trackmydoc gives you the right to install the Service for use by
                                        the total number of Users you identify and authorize. The Service may not be used or accessed by (a)
                                        individuals who are not named individuals; or (b) any other software or hardware device that does
                                        not require a named individual to use or access it. A named individual means an individual
                                        identified by you by name who is authorized to use the Service, regardless of how such access occurs
                                        or if such individual uses any hardware or software that reduces the apparent number of Users who
                                        are using the Service, such as by using a terminal service. The Service may not be used or accessed
                                        by any other software or hardware device that does not require an individual to use or access it.
                                        Trackmydoc reserves the right at any time to require you to provide a list of the named
                                        individual(s).
                                   </p>
                                   <p>
                                        You may not rent, lease, lend, sell, redistribute or sublease the Service. These Terms will govern
                                        any upgrades provided by Trackmydoc that replace and/or supplement the original Service. You agree
                                        to use your best efforts to protect the Service and upgrades from unauthorized use, reproduction,
                                        distribution, publication, or alteration.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">3. Payment Processing</h2>
                              <div>
                                   <p>
                                        In order to pay your Subscription Fees through the Services, you will be required to enter your
                                        first name, last name, email, phone number, billing address, credit card number, credit card expiry
                                        date, and card security code (CVC). This may be referred to in these TOS or the Privacy Policy as
                                        “Billing Information”.
                                   </p>
                                   <p>
                                        All payments are made using a secure https:// connection, and payment processing is handled through
                                        the “Third-Party Payment Processor” Stripe or CPAcharge, though this is subject to change without
                                        notice. The Third-Party Payment Processor currently accepts certain credit cards as payment options
                                        but these are subject to change without notice. Once transactions are accepted by the Third-Party
                                        Payment Processor, they are processed in accordance with their program rules and procedures and
                                        Terms of Service. Trackmydoc and the Third-Party Payment Processor are unaffiliated companies and
                                        Trackmydoc has no influence on the operations of the Third-Party Payment Processor. Trackmydoc
                                        and/or its Representatives shall in no way be held responsible for any losses or damages, direct or
                                        indirect, pecuniary or otherwise, resulting from any error or failure on the part of the Third-Party
                                        Payment Processor.
                                   </p>
                                   <p>
                                        All Billing Information is collected by the Third-Party Payment Processor, on their own secured
                                        servers. Trackmydoc does not have access to any credit card information, nor can it be responsible
                                        for any breach of information caused by faulty programming or malicious users on the servers of the
                                        Third-Party Payment Processor. Non-financial information will, however, be available to Trackmydoc
                                        for invoice-making and record-keeping purposes.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">4. Term and Fees</h2>
                              <div>
                                   <p>
                                        Provided prior notice of pricing is given to you, Trackmydoc reserves the right to charge you for
                                        use of the Service on a periodic (e.g., weekly or monthly) basis or otherwise. These Terms shall
                                        begin upon your registration for the Services and shall continue until your use of the Service is
                                        terminated by you or by Trackmydoc. You are responsible for all fees due to Trackmydoc and any
                                        compensation due prior to the termination date.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">5. Other Terms</h2>
                              <div>
                                   <p>
                                        Upon the termination of these Terms, the Service and all updates may cease to properly function, and
                                        all warranties, express or implied, regarding the Service shall terminate. Your rights under these
                                        terms will automatically terminate without notice from Trackmydoc if you fail to comply with any
                                        provision of these Terms. Further, Trackmydoc may terminate the Service for any action taken by you
                                        that Trackmydoc believes in its sole discretion is an inappropriate use of the Service even if not
                                        specifically detailed by these Terms, including any use of the Service that is prohibited by
                                        federal, state, or local law. Any warranty regarding the Service will automatically terminate
                                        without notice if you fail to comply with any provision of these Terms. The parties expressly
                                        acknowledge and agree that all provisions of these Terms that concern Copyright or other protectable
                                        interests of Trackmydoc shall remain in full force and effect notwithstanding termination of any
                                        warranty or use of the Service.
                                   </p>
                              </div>
                         </div>
                    </div>
               </div>
          ),
          "Privacy Shield Compliance": (
               <div>
                    <div className="mb-[60px]">
                         <h2 className="text-[50px] h2-font">Privacy Shield Compliance</h2>
                         <p className="text-[#7D8592] mb-4">
                              Trackmydoc, LLC (the "Company") is committed to ensuring the privacy and security of your personal data. As
                              part of our ongoing efforts to protect your privacy, we comply with the EU-U.S. Privacy Shield Framework and
                              the Swiss-U.S. Privacy Shield Frameworks (collectively, the "Privacy Shield Frameworks") as set forth by the
                              U.S. Department of Commerce regarding the collection, use, and retention of personal data transferred from the
                              European Union (EU) and Switzerland to the United States.
                         </p>
                         <p className="text-[#7D8592]">
                              Please read these Terms carefully before you begin using the Services. By using the Services or signing up for
                              an account, you accept and agree to be bound by and comply with these Terms, the Privacy Policy, and other
                              applicable policies and terms of the Agreement. If you do not agree to the entire Agreement, you may not
                              access or use the Services.
                         </p>
                    </div>
                    <div className="space-y-14">
                         <div>
                              <h2 className="text-[36px]">1. Privacy Shield Principles</h2>
                              <div>
                                   <p>Trackmydoc adheres to the following principles of the Privacy Shield Frameworks:</p>
                                   <ul>
                                        <li>
                                             <strong>Notice:</strong> We provide clear and conspicuous notice to individuals regarding the
                                             purposes for which we collect and use personal data.
                                        </li>
                                        <li>
                                             <strong>Choice:</strong> We offer individuals the opportunity to opt-out of the collection and
                                             use of their personal data for purposes that are not directly related to the services we
                                             provide.
                                        </li>
                                        <li>
                                             <strong>Accountability for Onward Transfer:</strong> We are responsible for ensuring that
                                             personal data is transferred in accordance with the Privacy Shield Principles.
                                        </li>
                                        <li>
                                             <strong>Security:</strong> We implement reasonable safeguards to protect personal data from
                                             unauthorized access and misuse.
                                        </li>
                                        <li>
                                             <strong>Data Integrity and Purpose Limitation:</strong> We ensure that personal data is
                                             accurate and used only for the purposes for which it was collected.
                                        </li>
                                        <li>
                                             <strong>Access:</strong> We provide individuals with access to their personal data and the
                                             ability to correct, amend, or delete it where necessary.
                                        </li>
                                        <li>
                                             <strong>Recourse, Enforcement, and Liability:</strong> We provide mechanisms for individuals to
                                             resolve complaints about our privacy practices.
                                        </li>
                                   </ul>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">2. Types of Data Collected</h2>
                              <div>
                                   <p>Trackmydoc collects personal data such as:</p>
                                   <ul>
                                        <li>Name, email address, billing address, phone number, and payment details.</li>
                                        <li>Other information necessary to provide the services you request.</li>
                                   </ul>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">3. How We Use Your Data</h2>
                              <div>
                                   <p>We use the personal data we collect to:</p>
                                   <ul>
                                        <li>Provide and manage our services.</li>
                                        <li>Process payments and communicate with you about your account and transactions.</li>
                                        <li>Improve the functionality and security of our services.</li>
                                   </ul>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">4. Third-Party Transfers</h2>
                              <div>
                                   <p>
                                        Trackmydoc may transfer personal data to third-party service providers who assist in delivering our
                                        services. These service providers are obligated to safeguard personal data and process it only in
                                        accordance with our instructions.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">5. Your Rights</h2>
                              <div>
                                   <p>
                                        You have the right to access, correct, or delete your personal data. You can exercise these rights
                                        by contacting us directly at [email address]. If you are located in the EU or Switzerland, you may
                                        also have the right to lodge a complaint with the relevant data protection authority.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">6. Dispute Resolution</h2>
                              <div>
                                   <p>
                                        If you have a complaint regarding our Privacy Shield compliance, we encourage you to first contact
                                        us at [email address] so we can attempt to resolve the issue. If the matter is not resolved through
                                        our internal processes, you may submit your complaint to the American Arbitration Association (AAA)
                                        for resolution.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">7. Changes to This Statement</h2>
                              <div>
                                   <p>
                                        We may update this Privacy Shield Compliance Statement from time to time to reflect changes in our
                                        practices or legal obligations. Any updates will be posted on our website, and the revised statement
                                        will be effective as of the date of posting.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">8. Contact Information</h2>
                              <div>
                                   <p>For questions or concerns regarding our Privacy Shield compliance, please contact:</p>
                                   <p>
                                        <strong>Trackmydoc, LLC</strong>
                                   </p>
                              </div>
                         </div>
                    </div>
               </div>
          ),
          Security: (
               <div>
                    <div className="mb-[60px]">
                         <h2 className="text-[50px] h2-font">Security</h2>
                         <p className="text-[#7D8592] mb-4">
                              At Trackmydoc, we take the security of your personal data and information seriously. We have implemented a
                              variety of security measures to ensure the confidentiality, integrity, and availability of your data. This
                              Security Statement outlines the security practices we follow to protect your information while using our
                              services.
                         </p>
                    </div>
                    <div className="space-y-14">
                         <div>
                              <h2 className="text-[36px]">1. Data Encryption</h2>
                              <div>
                                   <p>
                                        All sensitive data transmitted between your device and our servers is encrypted using Secure Socket
                                        Layer (SSL) technology. This ensures that any data exchanged is protected from unauthorized access
                                        during transmission.
                                   </p>
                                   <p>
                                        We also use encryption to store sensitive data at rest, ensuring that your personal information is
                                        protected even in the event of a breach.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">2. Authentication</h2>
                              <div>
                                   <p>
                                        To ensure that only authorized users can access your account, we use secure authentication methods,
                                        including:
                                   </p>
                                   <ul>
                                        <li>
                                             <strong>Two-Factor Authentication (2FA):</strong> We offer an additional layer of security by
                                             requiring a second form of verification, such as a code sent to your mobile device.
                                        </li>
                                        <li>
                                             <strong>Strong Password Policies:</strong> We enforce the use of strong, unique passwords to
                                             reduce the risk of unauthorized access to your account.
                                        </li>
                                   </ul>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">3. Secure Payment Processing</h2>
                              <div>
                                   <p>
                                        When you make payments through our platform, your payment information is processed by a third-party
                                        payment processor (e.g., Stripe, PayPal). These processors use industry-standard security protocols,
                                        including encryption, to protect your financial information.
                                   </p>
                                   <p>We do not store any sensitive payment information, such as credit card numbers, on our servers.</p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">4. Data Access Control</h2>
                              <div>
                                   <p>
                                        Access to your personal data is restricted to authorized personnel only. We implement role-based
                                        access control to ensure that employees or contractors who require access to data for their work can
                                        do so without compromising your privacy.
                                   </p>
                                   <p>
                                        Additionally, we regularly audit access logs to ensure that only authorized users have access to
                                        sensitive information.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">5. Regular Security Audits</h2>
                              <div>
                                   <p>
                                        We perform regular security audits and vulnerability assessments to identify potential security
                                        risks. These assessments help us ensure that our systems remain secure and that any vulnerabilities
                                        are addressed promptly.
                                   </p>
                                   <p>
                                        We also work with third-party security experts to conduct penetration testing and other security
                                        assessments to ensure that our platform remains secure against evolving threats.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">6. Incident Response</h2>
                              <div>
                                   <p>
                                        In the unlikely event of a data breach or security incident, we have an incident response plan in
                                        place to quickly address the situation. This includes:
                                   </p>
                                   <ul>
                                        <li>Notifying affected users as soon as possible.</li>
                                        <li>Investigating the cause of the breach and taking steps to mitigate further risks.</li>
                                        <li>Working with law enforcement and regulatory authorities as needed.</li>
                                   </ul>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">7. User Responsibilities</h2>
                              <div>
                                   <p>
                                        While we take extensive measures to protect your data, it is also important for you to play an
                                        active role in securing your account. We recommend the following:
                                   </p>
                                   <ul>
                                        <li>
                                             Use strong, unique passwords for your account and avoid reusing passwords across different
                                             platforms.
                                        </li>
                                        <li>Enable two-factor authentication (2FA) for an added layer of security.</li>
                                        <li>Notify us immediately if you suspect any unauthorized access to your account.</li>
                                   </ul>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">8. Changes to This Security Statement</h2>
                              <div>
                                   <p>
                                        We may update this Security Statement from time to time to reflect changes in our security practices
                                        or legal requirements. Any updates will be posted on our website, and the revised statement will be
                                        effective as of the date of posting.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">9. Contact Information</h2>
                              <div>
                                   <p>
                                        If you have any questions or concerns regarding our security practices or this Security Statement,
                                        please contact us:
                                   </p>
                                   <p>
                                        <strong>Trackmydoc, LLC</strong>
                                   </p>
                              </div>
                         </div>
                    </div>
               </div>
          ),
          "Security Review": (
               <div>
                    <div className="mb-[60px]">
                         <h2 className="text-[50px] h2-font">Security Review</h2>
                         <p className="text-[#7D8592] mb-4">
                              This Security Review outlines the security measures and practices we employ to ensure that your data and our
                              systems remain secure. The review assesses various aspects of our platform, including data encryption, access
                              control, incident response, and more. This document is part of our commitment to maintaining the highest
                              standards of security and ensuring the protection of your personal and financial information.
                         </p>
                    </div>
                    <div className="space-y-14">
                         <div>
                              <h2 className="text-[36px]">1. Overview of Security Measures</h2>
                              <div>
                                   <p>
                                        This section provides an overview of the security measures implemented across our platform to
                                        protect user data and prevent unauthorized access.
                                   </p>
                                   <ul>
                                        <li>
                                             <strong>Encryption:</strong> All sensitive data is encrypted both in transit and at rest using
                                             industry-standard encryption protocols.
                                        </li>
                                        <li>
                                             <strong>Authentication:</strong> We use strong authentication mechanisms, including two-factor
                                             authentication (2FA) and strong password policies.
                                        </li>
                                        <li>
                                             <strong>Access Control:</strong> We implement role-based access control (RBAC) to limit access
                                             to sensitive data based on user roles and responsibilities.
                                        </li>
                                        <li>
                                             <strong>Regular Audits:</strong> We perform regular security audits and vulnerability
                                             assessments to identify and address potential risks.
                                        </li>
                                   </ul>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">2. Data Encryption</h2>
                              <div>
                                   <p>
                                        Data encryption is a critical aspect of our security strategy. We use the following methods to
                                        ensure the confidentiality and integrity of your data:
                                   </p>
                                   <ul>
                                        <li>
                                             <strong>Encryption in Transit:</strong> All data transmitted between users and our servers is
                                             encrypted using SSL/TLS protocols, ensuring protection from man-in-the-middle attacks.
                                        </li>
                                        <li>
                                             <strong>Encryption at Rest:</strong> Sensitive data stored on our servers is encrypted using
                                             AES-256 encryption to protect against unauthorized access in the event of a breach.
                                        </li>
                                        <li>
                                             <strong>Key Management:</strong> We use a secure key management system to handle encryption
                                             keys and ensure they are rotated periodically to maintain security.
                                        </li>
                                   </ul>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">3. Authentication and Access Control</h2>
                              <div>
                                   <p>
                                        We implement strong authentication and access control measures to ensure that only authorized users
                                        can access sensitive data:
                                   </p>
                                   <ul>
                                        <li>
                                             <strong>Two-Factor Authentication (2FA):</strong> We offer 2FA for all user accounts to provide
                                             an additional layer of security when logging in.
                                        </li>
                                        <li>
                                             <strong>Role-Based Access Control (RBAC):</strong> We assign specific roles and permissions to
                                             users based on their responsibilities, ensuring that they only have access to the data
                                             necessary for their role.
                                        </li>
                                        <li>
                                             <strong>Session Management:</strong> We enforce session timeouts and re-authentication to
                                             minimize the risk of unauthorized access through abandoned sessions.
                                        </li>
                                   </ul>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">4. Payment Security</h2>
                              <div>
                                   <p>We use secure payment processing methods to protect your financial information:</p>
                                   <ul>
                                        <li>
                                             <strong>Third-Party Payment Processors:</strong> We rely on trusted third-party payment
                                             processors (e.g., Stripe, PayPal) to handle payment transactions securely.
                                        </li>
                                        <li>
                                             <strong>PCI-DSS Compliance:</strong> Our payment processing partners comply with the Payment
                                             Card Industry Data Security Standard (PCI-DSS) to ensure the secure handling of payment
                                             information.
                                        </li>
                                        <li>
                                             <strong>Tokenization:</strong> Sensitive payment data, such as credit card numbers, are
                                             tokenized to protect against data breaches.
                                        </li>
                                   </ul>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">5. Incident Response Plan</h2>
                              <div>
                                   <p>In the event of a security breach, we have a comprehensive incident response plan in place:</p>
                                   <ul>
                                        <li>
                                             <strong>Immediate Action:</strong> We will immediately investigate any potential security
                                             incidents to determine the scope and impact.
                                        </li>
                                        <li>
                                             <strong>Notification:</strong> Affected users will be notified promptly, and any necessary
                                             corrective actions will be taken.
                                        </li>
                                        <li>
                                             <strong>Root Cause Analysis:</strong> We will perform a root cause analysis to prevent similar
                                             incidents from occurring in the future.
                                        </li>
                                        <li>
                                             <strong>Collaboration with Authorities:</strong> If necessary, we will work with law
                                             enforcement and regulatory authorities to address the incident.
                                        </li>
                                   </ul>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">6. Regular Security Audits</h2>
                              <div>
                                   <p>
                                        We perform regular security audits to identify vulnerabilities and ensure that our security measures
                                        are up to date:
                                   </p>
                                   <ul>
                                        <li>
                                             <strong>Vulnerability Assessments:</strong> We conduct regular vulnerability assessments to
                                             identify potential weaknesses in our infrastructure.
                                        </li>
                                        <li>
                                             <strong>Penetration Testing:</strong> We perform penetration testing to simulate real-world
                                             attacks and identify potential entry points for attackers.
                                        </li>
                                        <li>
                                             <strong>Compliance Audits:</strong> We undergo regular audits to ensure compliance with
                                             relevant security regulations and industry standards.
                                        </li>
                                   </ul>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">7. User Responsibilities</h2>
                              <div>
                                   <p>We encourage our users to take an active role in securing their accounts:</p>
                                   <ul>
                                        <li>
                                             <strong>Use Strong Passwords:</strong> Users should create strong, unique passwords for their
                                             accounts to prevent unauthorized access.
                                        </li>
                                        <li>
                                             <strong>Enable Two-Factor Authentication (2FA):</strong> We recommend enabling 2FA for an added
                                             layer of security.
                                        </li>
                                        <li>
                                             <strong>Monitor Account Activity:</strong> Users should regularly monitor their accounts for
                                             any suspicious activity and report it immediately.
                                        </li>
                                   </ul>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">8. Security Improvements</h2>
                              <div>
                                   <p>We are committed to continuously improving our security posture. This includes:</p>
                                   <ul>
                                        <li>
                                             <strong>Regular Updates:</strong> We regularly update our systems and software to patch
                                             security vulnerabilities and improve overall security.
                                        </li>
                                        <li>
                                             <strong>Employee Training:</strong> We provide ongoing security training to our employees to
                                             ensure they are aware of the latest threats and best practices for data protection.
                                        </li>
                                        <li>
                                             <strong>Security Research:</strong> We invest in security research to stay ahead of emerging
                                             threats and adopt new technologies to enhance our security.
                                        </li>
                                   </ul>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">9. Contact Information</h2>
                              <div>
                                   <p>If you have any questions or concerns about our security practices, please contact us:</p>
                                   <p>
                                        <strong>Trackmydoc, LLC</strong>
                                   </p>
                              </div>
                         </div>
                    </div>
               </div>
          ),
          "Refund Policy": (
               <div>
                    <div className="mb-[60px]">
                         <h2 className="text-[50px] h2-font">Refund Policy</h2>
                         <p className="text-[#7D8592] mb-4">
                              At Trackmydoc, we strive to provide the best possible service to our customers. However, we understand that
                              there may be times when you may need to request a refund. This Refund Policy outlines the terms and conditions
                              under which refunds will be granted.
                         </p>
                    </div>
                    <div className="space-y-14">
                         <div>
                              <h2 className="text-[36px]">1. Eligibility for Refunds</h2>
                              <div>
                                   <p>Refunds will be considered under the following circumstances:</p>
                                   <ul>
                                        <li>
                                             <strong>Billing Errors:</strong> If you were incorrectly charged for services, we will provide
                                             a full refund for the overcharged amount.
                                        </li>
                                        <li>
                                             <strong>Service Not Delivered:</strong> If the service was not delivered as described or was
                                             unavailable for an extended period, you may be eligible for a refund.
                                        </li>
                                        <li>
                                             <strong>Unhappy with Service:</strong> If you are not satisfied with the service, you may
                                             request a refund within [X] days of purchase, provided you have not used the service
                                             extensively.
                                        </li>
                                        <li>
                                             <strong>Account Cancellation:</strong> If you cancel your account before the end of the billing
                                             cycle, we may provide a pro-rated refund for the unused portion of your subscription.
                                        </li>
                                   </ul>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">2. Non-Refundable Items</h2>
                              <div>
                                   <p>The following items and circumstances are non-refundable:</p>
                                   <ul>
                                        <li>
                                             <strong>Fees for Services Rendered:</strong> If services were rendered or partially used, no
                                             refund will be provided.
                                        </li>
                                        <li>
                                             <strong>Non-Usage of Service:</strong> If you did not use the service but the subscription
                                             period has passed, you are not eligible for a refund.
                                        </li>
                                        <li>
                                             <strong>Digital Products:</strong> Digital products such as downloadable resources or one-time
                                             use software licenses are non-refundable once accessed or downloaded.
                                        </li>
                                   </ul>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">3. How to Request a Refund</h2>
                              <div>
                                   <p>If you believe you are eligible for a refund, please follow the steps below:</p>
                                   <ul>
                                        <li>
                                             <strong>Contact Us:</strong> Reach out to our support team via email at [support email] or
                                             through the contact form on our website.
                                        </li>
                                        <li>
                                             <strong>Provide Details:</strong> Include your account details, a description of the issue, and
                                             the reason for the refund request.
                                        </li>
                                        <li>
                                             <strong>Refund Processing:</strong> Once your request is reviewed, we will process your refund
                                             request within [X] business days. Refunds will be issued to the original payment method.
                                        </li>
                                   </ul>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">4. Processing Time</h2>
                              <div>
                                   <p>
                                        Refunds are typically processed within [X] business days from the date your refund request is
                                        approved. The time it takes for the refund to appear in your account may vary depending on your
                                        payment provider.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">5. Changes to the Refund Policy</h2>
                              <div>
                                   <p>
                                        We reserve the right to update or modify this Refund Policy at any time. Any changes will be posted
                                        on this page, and the updated policy will take effect immediately upon posting. Please review this
                                        policy regularly to stay informed of any updates.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">6. Contact Information</h2>
                              <div>
                                   <p>
                                        If you have any questions or concerns regarding this Refund Policy or need assistance with your
                                        refund request, please contact us:
                                   </p>
                                   <p>
                                        <strong>Trackmydoc, LLC</strong>
                                   </p>
                              </div>
                         </div>
                    </div>
               </div>
          ),
          "Spam Guidelines": (
               <div>
                    <div className="mb-[60px]">
                         <h2 className="text-[50px] h2-font">Spam Guidelines</h2>
                         <p className="text-[#7D8592] mb-4">
                              At Trackmydoc, we are committed to maintaining a safe, respectful, and professional environment for all users
                              of our services. As part of this commitment, we have established these Spam Guidelines to define what
                              constitutes spam and to ensure that our platform is used responsibly and ethically.
                         </p>
                    </div>
                    <div className="space-y-14">
                         <div>
                              <h2 className="text-[36px]">1. Definition of Spam</h2>
                              <div>
                                   <p>
                                        For the purposes of these guidelines, spam refers to any unsolicited or unwanted communication that
                                        is sent in bulk, usually for commercial purposes. Spam can include, but is not limited to, the
                                        following:
                                   </p>
                                   <ul>
                                        <li>
                                             <strong>Unsolicited emails:</strong> Sending emails to users who have not opted-in or consented
                                             to receiving such communications.
                                        </li>
                                        <li>
                                             <strong>Unsolicited messages:</strong> Sending unsolicited direct messages via our platform or
                                             other communication channels.
                                        </li>
                                        <li>
                                             <strong>Bulk or automated messaging:</strong> Using automated systems or scripts to send bulk
                                             messages or perform actions that are intended to overwhelm or disrupt the service.
                                        </li>
                                        <li>
                                             <strong>Fake or misleading content:</strong> Sending content that misrepresents the sender’s
                                             identity, promotes fraudulent services, or misleads users.
                                        </li>
                                        <li>
                                             <strong>Malicious content:</strong> Sending messages that contain viruses, malware, or other
                                             harmful software intended to damage or disrupt users' devices or the service.
                                        </li>
                                   </ul>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">2. Prohibited Actions</h2>
                              <div>
                                   <p>
                                        The following actions are strictly prohibited on our platform and may result in the suspension or
                                        termination of your account:
                                   </p>
                                   <ul>
                                        <li>
                                             <strong>Sending unsolicited commercial messages:</strong> Sending marketing or promotional
                                             emails to users who have not consented to receive such communications.
                                        </li>
                                        <li>
                                             <strong>Using misleading or deceptive subject lines:</strong> Creating email subject lines or
                                             messages that deceive recipients about the content or sender.
                                        </li>
                                        <li>
                                             <strong>Harvesting or scraping contact information:</strong> Collecting email addresses, phone
                                             numbers, or other personal information from our users without their consent.
                                        </li>
                                        <li>
                                             <strong>Engaging in email bombing:</strong> Sending excessive or repeated messages to a user in
                                             a short period of time to overwhelm or annoy them.
                                        </li>
                                        <li>
                                             <strong>Using automated tools:</strong> Employing bots, scripts, or other automated tools to
                                             send bulk messages or conduct any form of spam-related activity.
                                        </li>
                                   </ul>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">3. Reporting Spam</h2>
                              <div>
                                   <p>
                                        If you encounter spam or suspect that spam-related activities are taking place on our platform, we
                                        encourage you to report it to us immediately. We take all reports seriously and will investigate the
                                        issue promptly.
                                   </p>
                                   <p>You can report spam by:</p>
                                   <ul>
                                        <li>
                                             <strong>Contacting Support:</strong> Email us at [support email] with details of the spam
                                             activity.
                                        </li>
                                        <li>
                                             <strong>Using the Reporting Feature:</strong> Use the “Report” button available in the platform
                                             to flag any suspicious or spam-related content.
                                        </li>
                                   </ul>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">4. Consequences of Violating Spam Guidelines</h2>
                              <div>
                                   <p>Any user found to be in violation of these Spam Guidelines will face the following consequences:</p>
                                   <ul>
                                        <li>
                                             <strong>Account Suspension:</strong> Your account may be temporarily suspended while we
                                             investigate the violation.
                                        </li>
                                        <li>
                                             <strong>Permanent Ban:</strong> If the violation is severe or repeated, your account may be
                                             permanently banned from using our services.
                                        </li>
                                        <li>
                                             <strong>Legal Action:</strong> In cases of fraudulent or illegal activity, we may pursue legal
                                             action against the responsible party.
                                        </li>
                                   </ul>
                                   <p>
                                        We reserve the right to take any actions necessary to protect our platform and users from
                                        spam-related activities.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">5. Best Practices for Preventing Spam</h2>
                              <div>
                                   <p>
                                        To ensure that you are using our platform responsibly and not engaging in spam-related activities,
                                        we recommend following these best practices:
                                   </p>
                                   <ul>
                                        <li>
                                             <strong>Get consent:</strong> Always obtain explicit consent from users before sending
                                             marketing or promotional messages.
                                        </li>
                                        <li>
                                             <strong>Provide an opt-out option:</strong> Ensure that all communications include a clear and
                                             easy way for recipients to unsubscribe or opt-out from future messages.
                                        </li>
                                        <li>
                                             <strong>Be transparent:</strong> Make sure your communications clearly identify you as the
                                             sender and accurately represent the content of the message.
                                        </li>
                                        <li>
                                             <strong>Monitor your messaging:</strong> Regularly review the content you send to ensure that
                                             it adheres to our guidelines and is not considered spam.
                                        </li>
                                   </ul>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">6. Changes to the Spam Guidelines</h2>
                              <div>
                                   <p>
                                        We reserve the right to update or modify these Spam Guidelines at any time. Any changes will be
                                        posted on this page, and the updated guidelines will take effect immediately upon posting. Please
                                        review this page regularly to stay informed of any updates.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">7. Contact Information</h2>
                              <div>
                                   <p>
                                        If you have any questions or concerns regarding these Spam Guidelines or need assistance with
                                        reporting spam, please contact us:
                                   </p>
                                   <p>
                                        <strong>Trackmydoc, LLC</strong>
                                   </p>
                              </div>
                         </div>
                    </div>
               </div>
          ),
          "Bug Bounty": (
               <div>
                    <div className="mb-[60px]">
                         <h2 className="text-[50px] h2-font">Bug Bounty Program</h2>
                         <p className="text-[#7D8592] mb-4">
                              At Trackmydoc, we take security seriously and believe that collaboration with the security research community
                              is crucial to maintaining a secure platform. Our Bug Bounty Program is designed to reward researchers who
                              discover and responsibly disclose security vulnerabilities in our platform.
                         </p>
                         <p className="text-[#7D8592]">
                              We invite you to participate in our Bug Bounty Program to help us identify and fix security vulnerabilities.
                              By doing so, you help ensure that our platform remains secure and that our users’ data is protected.
                         </p>
                    </div>
                    <div className="space-y-14">
                         <div>
                              <h2 className="text-[36px]">1. Eligibility</h2>
                              <div>
                                   <p>The Bug Bounty Program is open to individuals who:</p>
                                   <ul>
                                        <li>Are at least 18 years old or have legal parental/guardian consent.</li>
                                        <li>Have the necessary skills and knowledge to conduct security testing.</li>
                                        <li>Comply with the terms and conditions outlined in this Bug Bounty Program.</li>
                                   </ul>
                                   <p>
                                        By participating, you agree to follow the guidelines and disclose any findings in a responsible and
                                        ethical manner.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">2. Scope of the Bug Bounty Program</h2>
                              <div>
                                   <p>Our Bug Bounty Program covers the following:</p>
                                   <ul>
                                        <li>
                                             <strong>Web Application Vulnerabilities:</strong> Security issues related to our website or
                                             web-based services.
                                        </li>
                                        <li>
                                             <strong>Mobile Application Vulnerabilities:</strong> Security issues related to our mobile
                                             applications (iOS/Android).
                                        </li>
                                        <li>
                                             <strong>API Vulnerabilities:</strong> Security issues related to our public or private APIs.
                                        </li>
                                        <li>
                                             <strong>Other Security Issues:</strong> Any other vulnerabilities that could affect the
                                             security of our platform.
                                        </li>
                                   </ul>
                                   <p>
                                        We do not offer rewards for vulnerabilities related to third-party services or integrations, social
                                        engineering attacks, or issues that do not impact the security of the platform.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">3. What to Report</h2>
                              <div>
                                   <p>
                                        We encourage you to report any vulnerabilities that may compromise the security or privacy of our
                                        platform or users. Common examples of security issues we are interested in include:
                                   </p>
                                   <ul>
                                        <li>Cross-Site Scripting (XSS)</li>
                                        <li>SQL Injection</li>
                                        <li>Authentication and Authorization Issues</li>
                                        <li>Cross-Site Request Forgery (CSRF)</li>
                                        <li>Remote Code Execution (RCE)</li>
                                        <li>Sensitive Data Exposure</li>
                                        <li>Server Misconfigurations</li>
                                        <li>Privilege Escalation</li>
                                        <li>API Security Issues</li>
                                   </ul>
                                   <p>
                                        Please ensure that your report includes detailed steps to reproduce the issue, as well as any
                                        relevant information to help us understand the severity of the vulnerability.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">4. What Not to Report</h2>
                              <div>
                                   <p>We ask that you do not report the following types of issues:</p>
                                   <ul>
                                        <li>
                                             <strong>Social Engineering:</strong> Attempting to trick or manipulate our team or users.
                                        </li>
                                        <li>
                                             <strong>Denial of Service (DoS):</strong> Attacks that attempt to disrupt or degrade the
                                             service.
                                        </li>
                                        <li>
                                             <strong>Low-Risk Issues:</strong> Minor vulnerabilities that do not pose a significant risk to
                                             the platform.
                                        </li>
                                        <li>
                                             <strong>Out-of-Scope Vulnerabilities:</strong> Issues related to third-party services or
                                             integrations that we do not control.
                                        </li>
                                        <li>
                                             <strong>Known Vulnerabilities:</strong> Issues that have already been reported or are already
                                             publicly known.
                                        </li>
                                   </ul>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">5. Responsible Disclosure</h2>
                              <div>
                                   <p>When you find a security vulnerability, we ask that you follow responsible disclosure practices:</p>
                                   <ul>
                                        <li>
                                             <strong>Do not exploit the vulnerability:</strong> Please refrain from exploiting the
                                             vulnerability for personal gain or malicious purposes.
                                        </li>
                                        <li>
                                             <strong>Do not disrupt the service:</strong> Do not attempt to disrupt the availability of the
                                             platform or impact other users.
                                        </li>
                                        <li>
                                             <strong>Do not share the vulnerability:</strong> Please do not publicly disclose the
                                             vulnerability before it is fixed. We ask that you allow us a reasonable amount of time to
                                             address the issue before making it public.
                                        </li>
                                        <li>
                                             <strong>Contact us directly:</strong> Report the vulnerability directly to us through our
                                             designated reporting channels.
                                        </li>
                                   </ul>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">6. Rewards and Recognition</h2>
                              <div>
                                   <p>
                                        We offer rewards for valid and impactful vulnerability reports. The rewards are based on the
                                        severity of the vulnerability and the impact it has on the security of the platform. The following
                                        factors will be considered when determining the reward:
                                   </p>
                                   <ul>
                                        <li>
                                             <strong>Severity:</strong> The potential impact of the vulnerability on the platform and its
                                             users.
                                        </li>
                                        <li>
                                             <strong>Exploitability:</strong> How easily the vulnerability can be exploited by an attacker.
                                        </li>
                                        <li>
                                             <strong>Uniqueness:</strong> Whether the vulnerability is new or already known.
                                        </li>
                                   </ul>
                                   <p>
                                        Rewards can range from a thank-you note to monetary compensation, depending on the severity of the
                                        issue. All rewards are at our discretion, and we reserve the right to adjust the reward amount based
                                        on the specifics of the report.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">7. How to Report a Bug</h2>
                              <div>
                                   <p>If you discover a security vulnerability, please report it to us using the following methods:</p>
                                   <ul>
                                        <li>
                                             <strong>Bug Bounty Platform:</strong> Submit your report via our bug bounty platform at [bug
                                             bounty platform URL].
                                        </li>
                                        <li>
                                             <strong>Email:</strong> You can also email us directly at [security email address].
                                        </li>
                                   </ul>
                                   <p>Your report should include the following details:</p>
                                   <ul>
                                        <li>A description of the vulnerability.</li>
                                        <li>Steps to reproduce the issue.</li>
                                        <li>The impact and severity of the vulnerability.</li>
                                        <li>Any other relevant information or screenshots to help us understand the issue.</li>
                                   </ul>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">8. Program Rules</h2>
                              <div>
                                   <p>By participating in our Bug Bounty Program, you agree to the following rules:</p>
                                   <ul>
                                        <li>You will not engage in any activity that could harm our users or disrupt the service.</li>
                                        <li>You will not attempt to exploit vulnerabilities for personal gain or malicious purposes.</li>
                                        <li>
                                             You will only report vulnerabilities to us and not disclose them publicly until we have
                                             addressed the issue.
                                        </li>
                                        <li>
                                             You will comply with all applicable laws and regulations while participating in the program.
                                        </li>
                                   </ul>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">9. Changes to the Bug Bounty Program</h2>
                              <div>
                                   <p>
                                        We reserve the right to modify or discontinue the Bug Bounty Program at any time. Any changes will
                                        be posted on this page, and the updated program will take effect immediately upon posting. Please
                                        check this page regularly for updates.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">10. Contact Information</h2>
                              <div>
                                   <p>If you have any questions or need assistance with the Bug Bounty Program, please contact us:</p>
                                   <p>
                                        <strong>Trackmydoc, LLC</strong>
                                   </p>
                              </div>
                         </div>
                    </div>
               </div>
          ),
          "Partner Program Agreement": (
               <div>
                    <div className="mb-[60px]">
                         <h2 className="text-[50px] h2-font">Partner Program Agreement</h2>

                         <p className="text-[#7D8592]">
                              This Agreement governs the terms and conditions under which the Partner will participate in the Company’s
                              Partner Program.
                         </p>
                    </div>
                    <div className="space-y-14">
                         <div>
                              <h2 className="text-[36px]">1. Program Participation</h2>
                              <div>
                                   <p>
                                        The Partner agrees to participate in the Program in accordance with the terms and conditions set
                                        forth in this Agreement. The Program allows the Partner to promote and/or resell the Company's
                                        products and services in exchange for compensation as described herein.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">2. Responsibilities of the Partner</h2>
                              <div>
                                   <p>The Partner agrees to the following responsibilities:</p>
                                   <ul>
                                        <li>
                                             <strong>Promotion:</strong> The Partner will actively promote the Company's products and
                                             services through various channels, including but not limited to online marketing, email
                                             campaigns, and direct sales.
                                        </li>
                                        <li>
                                             <strong>Compliance:</strong> The Partner will comply with all applicable laws, regulations, and
                                             industry standards in the promotion of the Company’s products and services.
                                        </li>
                                        <li>
                                             <strong>Customer Support:</strong> The Partner will provide first-line customer support to
                                             customers acquired through their efforts, escalating issues to the Company as necessary.
                                        </li>
                                        <li>
                                             <strong>Brand Guidelines:</strong> The Partner will adhere to the Company’s brand guidelines
                                             when using the Company’s trademarks, logos, and other intellectual property in marketing
                                             materials.
                                        </li>
                                   </ul>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">3. Responsibilities of the Company</h2>
                              <div>
                                   <p>The Company agrees to the following responsibilities:</p>
                                   <ul>
                                        <li>
                                             <strong>Product Information:</strong> The Company will provide the Partner with up-to-date
                                             information about its products and services to facilitate accurate promotion and sales.
                                        </li>
                                        <li>
                                             <strong>Training and Support:</strong> The Company will provide necessary training and ongoing
                                             support to the Partner to ensure the success of the Program.
                                        </li>
                                        <li>
                                             <strong>Compensation:</strong> The Company will compensate the Partner in accordance with the
                                             compensation structure outlined in this Agreement.
                                        </li>
                                        <li>
                                             <strong>Marketing Materials:</strong> The Company will provide marketing materials and
                                             resources to assist the Partner in promoting its products and services.
                                        </li>
                                   </ul>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">4. Compensation</h2>
                              <div>
                                   <p>The Partner will be compensated based on the following structure:</p>
                                   <ul>
                                        <li>
                                             <strong>Commission:</strong> The Partner will earn a commission on each sale made through their
                                             efforts. The commission will be paid on a basis.
                                        </li>
                                        <li>
                                             <strong>Bonus Structure:</strong> The Partner may be eligible for additional bonuses based on
                                             performance metrics such as sales volume, customer acquisition, or other agreed-upon targets.
                                        </li>
                                        <li>
                                             <strong>Payment Terms:</strong> Payments will be made within days of the end of the payment
                                             period, subject to any necessary deductions for refunds or chargebacks.
                                        </li>
                                   </ul>
                                   <p>
                                        All compensation amounts are subject to applicable taxes, and the Partner is responsible for any
                                        taxes associated with their earnings.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">5. Term and Termination</h2>
                              <div>
                                   <p>
                                        This Agreement will begin on the Effective Date and will remain in effect until terminated by either
                                        Party. Either Party may terminate this Agreement at any time by providing days' written notice to
                                        the other Party.
                                   </p>
                                   <p>
                                        Upon termination, the Partner will cease all promotional activities related to the Company’s
                                        products and services and will return any materials or intellectual property provided by the
                                        Company.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">6. Confidentiality</h2>
                              <div>
                                   <p>
                                        Both Parties agree to keep confidential all information disclosed during the term of this Agreement
                                        that is designated as confidential or that a reasonable person would consider confidential. This
                                        includes, but is not limited to, business plans, marketing strategies, customer data, and financial
                                        information.
                                   </p>
                                   <p>
                                        Confidential information may not be disclosed to any third party without the prior written consent
                                        of the disclosing Party, except as required by law.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">7. Intellectual Property</h2>
                              <div>
                                   <p>
                                        Each Party retains ownership of its own intellectual property, including trademarks, copyrights, and
                                        patents. The Company grants the Partner a limited, non-exclusive license to use its trademarks and
                                        branding materials solely for the purpose of promoting its products and services under the terms of
                                        this Agreement.
                                   </p>
                                   <p>
                                        The Partner agrees not to use the Company’s intellectual property in any manner that could harm the
                                        Company’s reputation or brand.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">8. Indemnification</h2>
                              <div>
                                   <p>
                                        Each Party agrees to indemnify and hold harmless the other Party from any claims, damages, or
                                        liabilities arising out of the indemnifying Party’s breach of this Agreement, negligence, or
                                        misconduct. This includes, but is not limited to, any claims arising from the Partner’s promotional
                                        activities or the use of the Company’s products and services.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">9. Governing Law and Dispute Resolution</h2>
                              <div>
                                   <p>
                                        This Agreement will be governed by and construed in accordance with the laws of the State of without
                                        regard to its conflict of law principles. Any disputes arising under or in connection with this
                                        Agreement will be resolved through and the decision will be binding on both Parties.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">10. Miscellaneous</h2>
                              <div>
                                   <p>
                                        This Agreement constitutes the entire understanding between the Parties and supersedes all prior
                                        agreements or understandings, whether written or oral, related to the subject matter of this
                                        Agreement.
                                   </p>
                                   <p>Any amendments to this Agreement must be in writing and signed by both Parties.</p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">11. Execution</h2>
                              <div>
                                   <p>
                                        By signing below, the Parties acknowledge that they have read, understood, and agreed to the terms
                                        and conditions of this Agreement.
                                   </p>
                              </div>
                         </div>
                    </div>
               </div>
          ),
          "SMS Policy": (
               <div>
                    <div className="mb-[60px]">
                         <h2 className="text-[50px] h2-font">SMS Policy</h2>
                         <p className="text-[#7D8592] mb-4">
                              This SMS Policy outlines the terms and conditions under which sends SMS messages to users, customers, and
                              individuals . By providing your phone number to us or by opting into any of our SMS services, you consent to
                              receiving SMS communications as described in this Policy.
                         </p>
                         <p className="text-[#7D8592]">
                              Please read this Policy carefully before you provide your phone number or opt into any SMS communications. If
                              you do not agree with the terms of this Policy, you should not provide your phone number or opt into receiving
                              SMS communications.
                         </p>
                    </div>
                    <div className="space-y-14">
                         <div>
                              <h2 className="text-[36px]">1. Consent to Receive SMS</h2>
                              <div>
                                   <p>
                                        By providing your phone number to us, you consent to receive SMS messages from us, including but not
                                        limited to:
                                   </p>
                                   <ul>
                                        <li>Promotional messages</li>
                                        <li>Account updates</li>
                                        <li>Order confirmations</li>
                                        <li>Customer service communications</li>
                                        <li>Transactional messages</li>
                                   </ul>
                                   <p>You understand that your consent is not a condition of purchasing any goods or services from us.</p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">2. Frequency of SMS Messages</h2>
                              <div>
                                   <p>
                                        The frequency of SMS messages you receive will depend on your interactions with our services. You
                                        may receive SMS messages on a regular basis, including notifications related to your account,
                                        purchases, or promotional offers. However, you can opt-out of receiving these messages at any time
                                        by following the instructions provided in the messages.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">3. Opt-Out Procedure</h2>
                              <div>
                                   <p>
                                        If you no longer wish to receive SMS messages from us, you can opt-out at any time by replying
                                        "STOP" to any SMS message we send you. After opting out, you will no longer receive SMS messages
                                        unless you opt back in. Standard message and data rates may apply when you reply to opt-out.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">4. Message and Data Rates</h2>
                              <div>
                                   <p>
                                        Message and data rates may apply to any SMS messages you receive from us. These rates are determined
                                        by your mobile carrier and may vary depending on your service plan. Please check with your carrier
                                        for more information about applicable rates.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">5. Your Responsibilities</h2>
                              <div>
                                   <p>
                                        By opting into our SMS services, you agree to provide accurate and up-to-date information, including
                                        your phone number. You are responsible for notifying us if your phone number changes. You are also
                                        responsible for ensuring that your mobile device is capable of receiving SMS messages.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">6. Privacy and Data Protection</h2>
                              <div>
                                   <p>
                                        We are committed to protecting your privacy. Your phone number and any other personal information
                                        collected in connection with SMS communications will be handled in accordance with our Privacy
                                        Policy. We will not share your phone number with third parties without your consent, except as
                                        required by law or as necessary to provide the services you have requested.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">7. Compliance with Laws</h2>
                              <div>
                                   <p>
                                        We comply with all applicable laws and regulations related to SMS marketing, including the Telephone
                                        Consumer Protection Act (TCPA), the CAN-SPAM Act, and other applicable state and federal laws. You
                                        agree that you will not use our SMS services for any illegal, harmful, or unauthorized purposes.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">8. Limitation of Liability</h2>
                              <div>
                                   <p>
                                        We are not responsible for any delays, failures, or errors in the delivery of SMS messages, or for
                                        any issues arising from your mobile carrier or device. We are also not responsible for any charges
                                        or fees imposed by your mobile carrier for receiving SMS messages.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">9. Changes to the SMS Policy</h2>
                              <div>
                                   <p>
                                        We reserve the right to modify or update this SMS Policy at any time. Any changes will be effective
                                        immediately upon posting the updated policy on our website or notifying you through SMS. By
                                        continuing to receive SMS messages from us after any changes to this Policy, you agree to the
                                        updated terms.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">10. Contact Information</h2>
                              <div>
                                   <p>
                                        If you have any questions about this SMS Policy or need assistance with opting out or managing your
                                        SMS preferences
                                   </p>
                              </div>
                         </div>
                    </div>
               </div>
          ),
          "Referral Program Terms": (
               <div>
                    <div className="mb-[60px]">
                         <h2 className="text-[50px] h2-font">Referral Program Terms</h2>
                         <p className="text-[#7D8592] mb-4">
                              These Referral Program Terms govern your participation in the Referral Program. By participating in the
                              Program, you agree to abide by these Terms and any applicable laws. Please read them carefully before
                              participating in the Program.
                         </p>
                         <p className="text-[#7D8592]">
                              If you do not agree to these Terms, you should not participate in the Program. These Terms are in addition to
                              any other agreements you may have with .
                         </p>
                    </div>
                    <div className="space-y-14">
                         <div>
                              <h2 className="text-[36px]">1. Eligibility</h2>
                              <div>
                                   <p>To be eligible to participate in the Referral Program, you must:</p>
                                   <ul>
                                        <li>Be a registered user or customer of .</li>
                                        <li>Have a valid email address or phone number associated with your account.</li>
                                        <li>Comply with all applicable laws and regulations related to referral marketing.</li>
                                   </ul>
                                   <p>Employees, contractors, or affiliates of are not eligible to participate in the Program.</p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">2. How the Referral Program Works</h2>
                              <div>
                                   <p>
                                        As a participant in the Referral Program, you can refer individuals to by sharing your unique
                                        referral link or code. When a Referred Individual signs up for 's services and meets the eligibility
                                        criteria.
                                   </p>
                                   <p>
                                        The exact Referral Reward will be outlined on the Program page or communicated to you directly.
                                        Rewards may vary depending on the specific promotion or offer at the time of referral.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">3. Referral Rewards</h2>
                              <div>
                                   <p>Referral Rewards may include, but are not limited to:</p>
                                   <ul>
                                        <li>Discounts on future purchases or services.</li>
                                        <li>Cash bonuses or gift cards.</li>
                                        <li>Free services or upgrades.</li>
                                   </ul>
                                   <p>
                                        The value of the Referral Reward will be specified at the time of the referral and is subject to
                                        change at any time. Rewards are non-transferable and cannot be redeemed for cash unless explicitly
                                        stated.
                                   </p>
                                   <p>
                                        Referral Rewards are subject to the terms and conditions of and may be adjusted or revoked if the
                                        referral is deemed fraudulent or if the referred individual does not meet the criteria for
                                        eligibility.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">4. Referral Limitations</h2>
                              <div>
                                   <p>
                                        reserves the right to limit the number of referrals you can make and the number of Referral Rewards
                                        you can earn within a specified period. Additionally, referrals must be made in good faith, and any
                                        attempt to manipulate the system will result in disqualification from the Program and forfeiture of
                                        any earned rewards.
                                   </p>
                                   <p>
                                        Referral Rewards may not be combined with other promotions, discounts, or offers unless explicitly
                                        stated by .
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">5. Program Changes and Termination</h2>
                              <div>
                                   <p>
                                        reserves the right to modify, suspend, or terminate the Referral Program at any time, without
                                        notice, at its sole discretion. If the Program is terminated, you will be entitled to receive any
                                        Referral Rewards you have earned prior to the termination date, provided that the referral meets all
                                        eligibility requirements.
                                   </p>
                                   <p>Any changes to the Program will be communicated to you via email or through the Program’s website.</p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">6. Fraud and Abuse</h2>
                              <div>
                                   <p>
                                        takes fraudulent activity seriously. Any attempt to manipulate the referral system, such as by
                                        submitting fake referrals or using fraudulent information, will result in the immediate termination
                                        of your participation in the Program and the forfeiture of any Referral Rewards.
                                   </p>
                                   <p>
                                        reserves the right to investigate any suspicious activity and take appropriate legal action as
                                        necessary.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">7. Taxes</h2>
                              <div>
                                   <p>
                                        You are responsible for any taxes that may apply to the Referral Rewards you earn. [Your Company
                                        Name] will not be held liable for any taxes, fees, or other charges related to your participation in
                                        the Program. We recommend that you consult with a tax professional if you have any questions
                                        regarding tax obligations related to the Referral Program.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">8. Limitation of Liability</h2>
                              <div>
                                   <p>
                                        To the fullest extent permitted by law, is not liable for any indirect, incidental, special,
                                        consequential, or punitive damages arising out of or in connection with the Referral Program,
                                        including but not limited to lost profits, revenue, or data, even if we were advised of the
                                        possibility of such damages.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">9. Privacy</h2>
                              <div>
                                   <p>
                                        By participating in the Referral Program, you consent to the collection, use, and processing of your
                                        personal data as outlined in 's Privacy Policy. We may collect personal information such as your
                                        name, email address, and referral activity to administer the Program and provide you with rewards.
                                   </p>
                                   <p>
                                        We will not share your personal information with third parties without your consent, except as
                                        required by law or as necessary to fulfill the Program.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">10. Governing Law</h2>
                              <div>
                                   <p>
                                        These Terms and the Referral Program will be governed by and construed in accordance without regard
                                        to its conflict of law principles. Any disputes arising from the Program will be resolved in the
                                        courts located.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">11. Contact Information</h2>
                              <div>
                                   <p>If you have any questions about the Referral Program or these Terms</p>
                              </div>
                         </div>
                    </div>
               </div>
          ),
          "Gramm-Leach-Bliley Compliance": (
               <div>
                    <div className="mb-[60px]">
                         <h2 className="text-[50px] h2-font">Gramm-Leach-Bliley Compliance</h2>
                         <p className="text-[#7D8592] mb-4">
                              is committed to protecting the privacy and security of our customers' nonpublic personal information (NPI) in
                              compliance with the Gramm-Leach-Bliley Act (GLBA). This policy outlines how we collect, use, and protect NPI
                              in accordance with GLBA's privacy and security provisions.
                         </p>
                         <p className="text-[#7D8592]">
                              By using our services, you agree to our compliance with the GLBA and the practices outlined in this policy. If
                              you do not agree with the terms, please refrain from using our services.
                         </p>
                    </div>
                    <div className="space-y-14">
                         <div>
                              <h2 className="text-[36px]">1. Information We Collect</h2>
                              <div>
                                   <p>
                                        In accordance with the GLBA, we collect the following types of nonpublic personal information (NPI)
                                        from our customers:
                                   </p>
                                   <ul>
                                        <li>Personal information such as name, address, and contact details.</li>
                                        <li>Financial information including account numbers, balances, and transaction history.</li>
                                        <li>Information about our customers' transactions with us or others.</li>
                                        <li>Other information that may be provided during the course of using our services.</li>
                                   </ul>
                                   <p>
                                        We may also collect non-personally identifiable information, such as usage data and cookies, for
                                        operational purposes. However, this information is not considered NPI under the GLBA.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">2. Use of Information</h2>
                              <div>
                                   <p>We use the nonpublic personal information we collect for the following purposes:</p>
                                   <ul>
                                        <li>To provide services and products requested by customers.</li>
                                        <li>To communicate with customers regarding their accounts, products, and services.</li>
                                        <li>To improve our services and offerings.</li>
                                        <li>To comply with legal, regulatory, and contractual obligations.</li>
                                        <li>To detect, prevent, and respond to fraud or security incidents.</li>
                                   </ul>
                                   <p>
                                        We do not use customer information for any purpose other than those outlined in this policy, unless
                                        required by law or with the customer's consent.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">3. Sharing of Information</h2>
                              <div>
                                   <p>We do not share nonpublic personal information with third parties except as follows:</p>
                                   <ul>
                                        <li>
                                             With affiliates or service providers who assist us in providing services to our customers and
                                             who are bound by confidentiality agreements.
                                        </li>
                                        <li>With regulatory authorities, government agencies, or law enforcement as required by law.</li>
                                        <li>To comply with subpoenas, court orders, or legal processes.</li>
                                        <li>
                                             With third parties for marketing purposes, but only if we have obtained the customer's explicit
                                             consent in accordance with applicable laws.
                                        </li>
                                   </ul>
                                   <p>
                                        We do not sell, rent, or share NPI with non-affiliated third parties for marketing purposes without
                                        your consent.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">4. Safeguarding Customer Information</h2>
                              <div>
                                   <p>We take the following measures to safeguard the nonpublic personal information of our customers:</p>
                                   <ul>
                                        <li>Encryption of sensitive data during transmission and storage.</li>
                                        <li>Access controls to ensure that only authorized personnel can access NPI.</li>
                                        <li>Regular audits and monitoring of our systems for security vulnerabilities.</li>
                                        <li>Employee training on privacy and data protection best practices.</li>
                                        <li>Data retention policies to securely dispose of NPI that is no longer needed.</li>
                                   </ul>
                                   <p>
                                        While we take reasonable steps to protect your information, no security measure is entirely
                                        foolproof. We cannot guarantee the absolute security of your information.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">5. Customer Rights</h2>
                              <div>
                                   <p>
                                        Under the GLBA, customers have the following rights regarding their nonpublic personal information:
                                   </p>
                                   <ul>
                                        <li>The right to access and review the information we hold about them.</li>
                                        <li>The right to request corrections to any inaccurate information.</li>
                                        <li>
                                             The right to opt-out of the sharing of their NPI with non-affiliated third parties for
                                             marketing purposes, where applicable.
                                        </li>
                                   </ul>
                                   <p>
                                        If you wish to exercise any of these rights, please contact us using the contact information
                                        provided below.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">6. Privacy Notice</h2>
                              <div>
                                   <p>
                                        We provide a Privacy Notice that explains how we collect, use, and share your nonpublic personal
                                        information. This notice is available to you upon request, and we will provide you with a copy of
                                        the Privacy Notice whenever there are material changes to our practices or at least once a year, as
                                        required by the GLBA.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">7. Compliance with GLBA</h2>
                              <div>
                                   <p>
                                        is committed to ensuring that our practices comply with the Gramm-Leach-Bliley Act (GLBA) and other
                                        applicable privacy laws. We review and update our policies and procedures regularly to ensure
                                        compliance and to address new legal and regulatory requirements.
                                   </p>
                                   <p>
                                        We maintain a dedicated compliance team to oversee our GLBA-related activities and ensure that we
                                        meet all regulatory obligations regarding the privacy and security of customer information.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">8. Changes to this Policy</h2>
                              <div>
                                   <p>
                                        We reserve the right to update or modify this Gramm-Leach-Bliley Compliance Policy at any time. Any
                                        changes will be communicated to you via email or posted on our website. We encourage you to review
                                        this policy periodically to stay informed about how we are protecting your nonpublic personal
                                        information.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">9. Contact Information</h2>
                              <div>
                                   <p>
                                        If you have any questions or concerns regarding our Gramm-Leach-Bliley Compliance Policy or the
                                        privacy of your nonpublic personal information
                                   </p>
                              </div>
                         </div>
                    </div>
               </div>
          ),
          "Trackmydoc Academy Terms of Use": (
               <div>
                    <div className="mb-[60px]">
                         <h2 className="text-[50px] h2-font">Trackmydoc Academy Terms of Use</h2>
                         <p className="text-[#7D8592] mb-4">
                              Welcome to Trackmydoc Academy! These Terms of Use ("Terms") govern your access to and use of the educational
                              resources, courses, and services provided by Trackmydoc Academy. By accessing or using Trackmydoc Academy, you
                              agree to comply with and be bound by these Terms. If you do not agree with these Terms, you may not access or
                              use Trackmydoc Academy.
                         </p>
                         <p className="text-[#7D8592]">
                              Please read these Terms carefully before using Trackmydoc Academy. These Terms apply to all users, including
                              students, instructors, and any other individuals who access or use the Academy’s services.
                         </p>
                    </div>
                    <div className="space-y-14">
                         <div>
                              <h2 className="text-[36px]">1. Access to Trackmydoc Academy</h2>
                              <div>
                                   <p>
                                        To access Trackmydoc Academy, you must create an account by providing accurate and up-to-date
                                        information. You are responsible for maintaining the confidentiality of your account credentials and
                                        for all activities that occur under your account.
                                   </p>
                                   <p>
                                        By using Trackmydoc Academy, you agree to comply with all applicable laws and regulations. You may
                                        only use the Academy for lawful purposes and in accordance with these Terms.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">2. Use of Educational Content</h2>
                              <div>
                                   <p>
                                        The content provided on Trackmydoc Academy, including courses, videos, articles, and other materials
                                        , is protected by intellectual property laws and is the property of Trackmydoc or its licensors. You
                                        are granted a limited, non-exclusive, non-transferable license to access and use the Content for
                                        personal, non-commercial purposes only.
                                   </p>
                                   <p>
                                        You may not reproduce, distribute, modify, or create derivative works of the Content without prior
                                        written permission from Trackmydoc.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">3. User Conduct</h2>
                              <div>
                                   <p>As a user of Trackmydoc Academy, you agree to:</p>
                                   <ul>
                                        <li>
                                             Use the Academy in a manner that does not infringe the rights of others or violate any
                                             applicable laws or regulations.
                                        </li>
                                        <li>
                                             Not engage in any conduct that disrupts or interferes with the functioning of the Academy or
                                             the experience of other users.
                                        </li>
                                        <li>
                                             Not upload, post, or share any content that is offensive, harmful, or violates the rights of
                                             others.
                                        </li>
                                        <li>
                                             Comply with all applicable terms and conditions when interacting with other users or
                                             instructors on the platform.
                                        </li>
                                   </ul>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">4. Account Termination</h2>
                              <div>
                                   <p>
                                        Trackmydoc reserves the right to suspend or terminate your account if you violate these Terms or
                                        engage in any conduct that is harmful to the Academy or other users. Upon termination, you will lose
                                        access to all Content and services associated with your account.
                                   </p>
                                   <p>
                                        You may also terminate your account at any time by contacting Trackmydoc support or through your
                                        account settings.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">5. Fees and Payments</h2>
                              <div>
                                   <p>
                                        Trackmydoc Academy may charge fees for certain courses or services. The fees and payment terms will
                                        be clearly outlined at the time of purchase. By purchasing a course or service, you agree to pay the
                                        applicable fees in full.
                                   </p>
                                   <p>
                                        All payments must be made through the payment methods provided on the Academy platform. If payment
                                        is not received or is declined, access to the courses or services may be suspended or terminated.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">6. Refund Policy</h2>
                              <div>
                                   <p>
                                        Refunds for courses or services provided by Trackmydoc Academy are subject to the refund policy
                                        outlined on the Academy platform. Please review the refund policy before making any purchase.
                                        Refunds may be granted under certain conditions, as specified in the policy.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">7. Privacy and Data Protection</h2>
                              <div>
                                   <p>
                                        Your privacy is important to us. Please review our Privacy Policy to understand how we collect, use,
                                        and protect your personal information when you use Trackmydoc Academy.
                                   </p>
                                   <p>
                                        By using the Academy, you consent to the collection and use of your personal information in
                                        accordance with our Privacy Policy.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">8. Disclaimers and Limitation of Liability</h2>
                              <div>
                                   <p>
                                        Trackmydoc Academy provides its services and Content "as is" and makes no representations or
                                        warranties of any kind, either express or implied, regarding the accuracy, completeness, or
                                        usefulness of the Content. We do not guarantee that the Academy will be free from errors or
                                        interruptions.
                                   </p>
                                   <p>
                                        Trackmydoc is not liable for any damages, losses, or costs incurred as a result of using the
                                        Academy, including but not limited to indirect, incidental, or consequential damages.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">9. Changes to the Terms of Use</h2>
                              <div>
                                   <p>
                                        Trackmydoc reserves the right to modify or update these Terms at any time. Any changes will be
                                        posted on the Academy website, and you will be notified of any material changes. By continuing to
                                        use the Academy after changes are made, you agree to be bound by the updated Terms.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">10. Governing Law</h2>
                              <div>
                                   <p>
                                        These Terms are governed by the laws of the jurisdiction in which Trackmydoc is located, without
                                        regard to its conflict of law principles. Any disputes arising from these Terms shall be resolved in
                                        the appropriate courts located
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">11. Contact Information</h2>
                              <div>
                                   <p>If you have any questions or concerns regarding these Terms of Use</p>
                              </div>
                         </div>
                    </div>
               </div>
          ),
          "Trackmydoc webinars and online events: terms of use": (
               <div>
                    <div className="mb-[60px]">
                         <h2 className="text-[50px] h2-font">Trackmydoc Webinars and Online Events: Terms of Use</h2>
                         <p className="text-[#7D8592] mb-4">
                              Welcome to Trackmydoc's Webinars and Online Events! These Terms of Use govern your access to and participation
                              in webinars and other online events hosted by Trackmydoc . By registering for or attending any Webinar or
                              Event, you agree to comply with and be bound by these Terms. If you do not agree to these Terms, you may not
                              participate in the Webinar or Event.
                         </p>
                         <p className="text-[#7D8592]">
                              Please read these Terms carefully before registering for or attending any Webinar or Event. These Terms apply
                              to all participants, including attendees, speakers, and any other individuals who access or participate in the
                              Webinars or Events.
                         </p>
                    </div>
                    <div className="space-y-14">
                         <div>
                              <h2 className="text-[36px]">1. Registration and Access</h2>
                              <div>
                                   <p>
                                        To participate in a Trackmydoc Webinar or Event, you must complete the registration process by
                                        providing accurate and up-to-date information. You will be provided with access details, such as
                                        links and login credentials, after your registration is confirmed.
                                   </p>
                                   <p>
                                        By registering, you agree to receive notifications and updates related to the Webinar or Event,
                                        including changes to schedules or speakers.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">2. Use of Webinars and Event Content</h2>
                              <div>
                                   <p>
                                        All content presented during Trackmydoc Webinars or Events, including but not limited to
                                        presentations, videos, documents, and other materials, is protected by copyright and intellectual
                                        property laws. Trackmydoc grants you a limited, non-exclusive, non-transferable license to view the
                                        Content for personal, non-commercial purposes only.
                                   </p>
                                   <p>
                                        You may not record, reproduce, distribute, or create derivative works of the Content without prior
                                        written permission from Trackmydoc.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">3. User Conduct</h2>
                              <div>
                                   <p>By participating in Trackmydoc Webinars or Events, you agree to:</p>
                                   <ul>
                                        <li>Behave respectfully and professionally during the Webinar or Event.</li>
                                        <li>
                                             Not engage in any disruptive behavior that interferes with the experience of other participants
                                             or speakers.
                                        </li>
                                        <li>
                                             Not share offensive, harmful, or inappropriate content or comments during the Webinar or Event.
                                        </li>
                                        <li>
                                             Not record, photograph, or broadcast the Webinar or Event without express permission from
                                             Trackmydoc.
                                        </li>
                                   </ul>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">4. Event Changes and Cancellations</h2>
                              <div>
                                   <p>
                                        Trackmydoc reserves the right to modify, reschedule, or cancel any Webinar or Event at any time. In
                                        the event of a cancellation, Trackmydoc will make reasonable efforts to notify registered
                                        participants and offer alternative options, if applicable.
                                   </p>
                                   <p>
                                        Trackmydoc is not responsible for any costs or damages incurred by participants due to event changes
                                        or cancellations.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">5. Fees and Payment</h2>
                              <div>
                                   <p>
                                        Some Webinars or Events may require a registration fee. If a fee applies, the payment terms will be
                                        clearly outlined during the registration process. By registering for a paid event, you agree to pay
                                        the applicable fee.
                                   </p>
                                   <p>
                                        All payments must be made through the payment methods provided on the Trackmydoc platform. If
                                        payment is not received, access to the Webinar or Event may be denied.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">6. Privacy and Data Protection</h2>
                              <div>
                                   <p>
                                        Trackmydoc respects your privacy. Any personal information collected during the registration process
                                        or participation in Webinars or Events will be handled in accordance with our Privacy Policy.
                                   </p>
                                   <p>
                                        By registering for and participating in a Webinar or Event, you consent to the collection, use, and
                                        processing of your personal information as outlined in the Privacy Policy.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">7. Disclaimers and Limitation of Liability</h2>
                              <div>
                                   <p>
                                        Trackmydoc makes no representations or warranties regarding the accuracy, completeness, or
                                        usefulness of the Content presented during the Webinars or Events. The Webinars and Events are
                                        provided "as is" and without any warranty of any kind, express or implied.
                                   </p>
                                   <p>
                                        Trackmydoc is not responsible for any damages, losses, or costs incurred as a result of attending or
                                        participating in a Webinar or Event, including but not limited to direct, indirect, incidental, or
                                        consequential damages.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">8. Recording and Use of Participant Information</h2>
                              <div>
                                   <p>
                                        Trackmydoc may record Webinars and Events for the purpose of creating educational content, improving
                                        services, or for marketing purposes. By participating in a Webinar or Event, you consent to being
                                        recorded and agree that your name, image, and comments may be used in future promotional materials
                                        or educational content, unless you opt-out in advance.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">9. Governing Law</h2>
                              <div>
                                   <p>
                                        These Terms are governed by the laws of the jurisdiction in which Trackmydoc is located, without
                                        regard to its conflict of law principles. Any disputes arising from these Terms shall be resolved in
                                        the appropriate courts located.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">10. Changes to the Terms of Use</h2>
                              <div>
                                   <p>
                                        Trackmydoc reserves the right to modify or update these Terms at any time. Any changes will be
                                        posted on the Trackmydoc website, and you will be notified of any material changes. By continuing to
                                        participate in the Webinars or Events after changes are made, you agree to be bound by the updated
                                        Terms.
                                   </p>
                              </div>
                         </div>
                         <div>
                              <h2 className="text-[36px]">11. Contact Information</h2>
                              <div>
                                   <p>If you have any questions or concerns regarding these Terms of Use</p>
                              </div>
                         </div>
                    </div>
               </div>
          ),
     };
     // useEffect(() => {
     //      const hash = window.location.hash;
     //      if (hash) {
     //           const element = document.querySelector(hash);
     //           if (element) {
     //                element.scrollIntoView({ behavior: "smooth" });
     //           }
     //      }
     // }, []);
     console.log(location.state, "[][][][][][][][[[][]][][");
     // useEffect(() => {
     //      if (selectedSection == null) {
     //           setSelectedSection("Terms of Service");
     //      } else {
     //           setSelectedSection(location.state?.change);
     //      }
     // }, []);
     useEffect(() => {
          // If location.state?.change exists, update selectedSection
          if (location.state?.change) {
               setSelectedSection(location.state.change);
          }
     }, [location.state]);

     return (
          <>
               <section className="bg-[#FAFAFA]">
                    <div className="website-container">
                         {/* Left Menu */}
                         <div className="flex flex-important-terms py-[120px] max-[600px]:py-[20px]">
                              <div className="left-menu">
                                   {Object.keys(sections).map((section) => (
                                        // <Link to={`/terms-of-services#${section.replace(/\s+/g, "-").toLowerCase()}`}>
                                        <div
                                             key={section}
                                             className={`menu-item ${selectedSection === section ? "active" : ""}`}
                                             onClick={() => setSelectedSection(section)}
                                        >
                                             {section}
                                        </div>
                                        // </Link>
                                   ))}
                              </div>

                              {/* Right Content */}
                              {/* <div className="right-content">{sections[selectedSection]}</div> */}
                              <div className="right-content">{sections[selectedSection]}</div>
                         </div>
                    </div>
               </section>
          </>
     );
}

export default Terms;
