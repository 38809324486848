import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

import logo from "../assets/img/logo.svg";

import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

function Header() {
     const [activeDropdown, setActiveDropdown] = useState(null);

     // Toggle the dropdown
     const handleDropdownToggle = (dropdown) => {
          setActiveDropdown((prev) => (prev === dropdown ? null : dropdown));
     };

     // Close the dropdown when a link is clicked
     const closeDropdown = () => {
          setActiveDropdown(null);
     };

     return (
          <Navbar expand="lg" className="bg-body-white">
               <Container>
                    <Navbar.Brand className="p-0">
                         <Link to="/">
                              {" "}
                              <img src={logo} className="d-inline-block align-top" alt="React Bootstrap logo" />
                         </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                         <Nav className="m-auto my-2 my-lg-0" navbarScroll>
                              <Link to="/" className="nav-link">
                                   Home
                              </Link>
                              {/* Product Dropdown */}
                              <NavDropdown
                                   title="Product"
                                   show={activeDropdown === "product"}
                                   onToggle={() => handleDropdownToggle("product")}
                                   className="text-[20px] dropdown-new"
                              >
                                   <Link className="nav-link" to="/product-overview" onClick={closeDropdown}>
                                        Product Overview
                                   </Link>
                                   <Link className="nav-link" to="/crm" onClick={closeDropdown}>
                                        CRM & Mobile Apps
                                   </Link>
                                   <Link className="nav-link" to="/tax-organizers" onClick={closeDropdown}>
                                        Tax Organizers
                                   </Link>
                                   <Link className="nav-link" to="/time-billing" onClick={closeDropdown}>
                                        Time Billing
                                   </Link>
                                   <Link className="nav-link" to="/website-branding" onClick={closeDropdown}>
                                        Website Branding
                                   </Link>
                                   <Link className="nav-link" to="/team-management" onClick={closeDropdown}>
                                        Team Management
                                   </Link>
                                   <Link className="nav-link" to="/document-management" onClick={closeDropdown}>
                                        Document Management
                                   </Link>
                                   <Link className="nav-link" to="/secure-messages" onClick={closeDropdown}>
                                        Secure Messages
                                   </Link>
                                   <Link className="nav-link" to="/client-portal" onClick={closeDropdown}>
                                        Client Portal
                                   </Link>
                                   <Link className="nav-link" to="/inbox" onClick={closeDropdown}>
                                        Inbox
                                   </Link>
                                   <div class="pyramid-two"></div>
                              </NavDropdown>

                              {/* Integration Dropdown */}
                              <NavDropdown
                                   title="Integration"
                                   show={activeDropdown === "integration"}
                                   onToggle={() => handleDropdownToggle("integration")}
                              >
                                   <Link className="nav-link" to="/integration-overview" onClick={closeDropdown}>
                                        Integration Overview
                                   </Link>
                                   <Link className="nav-link" to="/payment-processor" onClick={closeDropdown}>
                                        Payment Processor
                                   </Link>
                                   <div class="pyramid"></div>
                              </NavDropdown>

                              <Link to="/pricing" className="nav-link">
                                   Pricing
                              </Link>
                              <NavDropdown title="About" show={activeDropdown === "About"} onToggle={() => handleDropdownToggle("About")}>
                                   <Link className="nav-link" to="/about" onClick={closeDropdown}>
                                        About Us
                                   </Link>
                                   <Link className="nav-link" to="/our-values" onClick={closeDropdown}>
                                        Our values
                                   </Link>
                                   <Link className="nav-link" to="/terms-of-services" onClick={closeDropdown}>
                                        Terms of services
                                   </Link>
                                   <Link className="nav-link" to="/testimonials" onClick={closeDropdown}>
                                        Testimonials
                                   </Link>
                                   <div class="pyramid"></div>
                              </NavDropdown>
                         </Nav>
                         <div className="d-flex">
                              <Link to="https://staging-cabinet.trackmydoc.com/login" className="btn btn-outline-primary mr-20">
                                   Login
                              </Link>
                              <Link to="/contact" className="btn btn-primary">
                                   Request Demo
                              </Link>
                         </div>
                    </Navbar.Collapse>
               </Container>
          </Navbar>
     );
}
export default Header;
