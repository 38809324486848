import React from "react";
import { Col, Row } from "react-bootstrap";
import Fortitude from "../../assets/img/Fortitude.svg";
import Sustainability from "../../assets/img/Sustainability.svg";
import Balance from "../../assets/img/Balance.svg";
import Focus from "../../assets/img/Focus.svg";
import Pragmatism from "../../assets/img/Pragmatism.svg";
import Usefulness from "../../assets/img/Usefulness.svg";
import Simplicity from "../../assets/img/Simplicity.svg";

function OurValue() {
     return (
          <>
               <div className="customization common-padding">
                    <div className="website-container">
                         <div className="mb-80">
                              <h2 className="mb-20">Our Values</h2>
                         </div>
                         <Row>
                              <Col xs={12} md={6} lg={4}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={Fortitude} />
                                             </div>
                                             <h5 className="mb-0">1.Fortitude</h5>
                                        </div>
                                        <p className="min-height-48">
                                             We are committed to the long term (10+ years) ; that kind of longevity is built on a strong
                                             foundation of organic growth, which is slower to foster, but much more sturdy.
                                        </p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={Sustainability} />
                                             </div>
                                             <h5 className="mb-0">2.Sustainability</h5>
                                        </div>
                                        <p className="min-height-48">
                                             Our business believes in taking the time to build a model that ensures all stakeholders get
                                             what they want today and tomorrow.
                                        </p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={Balance} />
                                             </div>
                                             <h5 className="mb-0">3.Balance</h5>
                                        </div>
                                        <p className="min-height-48">
                                             We build software to make your day to day easier; and we stress the importance of a positive
                                             environment internally, too; where progress and passtime coexist.
                                        </p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={Focus} />
                                             </div>
                                             <h5 className="mb-0">4.Focus</h5>
                                        </div>
                                        <p className="min-height-48">
                                             Everything we do is in service of people: our team, our clients and our clients’ clients. It is
                                             our purpose to provide real solutions for real people.
                                        </p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={Pragmatism} />
                                             </div>
                                             <h5 className="mb-0">5.Pragmatism</h5>
                                        </div>
                                        <p className="min-height-48">
                                             Sure, we’re tech nerds, but we are careful to not chase the latest «tech bells-and-whistles»
                                             for its own sake. We always choose the tested route optimized for end results.
                                        </p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={Usefulness} />
                                             </div>
                                             <h5 className="mb-0">6.Usefulness</h5>
                                        </div>
                                        <p className="min-height-48">
                                             We exist to solve our clients’ problems. Whether it’s helping our clients unlock their firms’
                                             true potential or helping their customers save time.
                                        </p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={Simplicity} />
                                             </div>
                                             <h5 className="mb-0">7.Simplicity</h5>
                                        </div>
                                        <p className="min-height-48">
                                             We believe Albert Einstein said it best when he said, «Everything should be made as simple as
                                             possible, but not simpler.»
                                        </p>
                                   </div>
                              </Col>
                         </Row>
                    </div>
               </div>
          </>
     );
}

export default OurValue;
