import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";

import testimonial from "../../assets/img/testimonial.png";
import smallBusinessLeader from "../../assets/img/trusted/small-business-leader.svg";
import highPerformer from "../../assets/img/trusted/highperformer.svg";
import leaderLeader from "../../assets/img/trusted/leader-leader.svg";
import accountingSmall from "../../assets/img/trusted/accounting-small-business-leader.svg";
import momentumleaderLeader from "../../assets/img/trusted/momentumleader-leader.svg";
import quote from "../../assets/img/quote.svg";
import review1 from "../../assets/img/review-1.svg";
import starEmpty from "../../assets/img/star-empty.svg";
import starFill from "../../assets/img/star-fill.svg";

function Testimonials() {
     const [add, setAdd] = useState(false);

     const handleAdd = () => {
          setAdd(!add);
     };
     return (
          <>
               <div className="globe common-margin relative z-10">
                    <div className="website-container">
                         <Row>
                              <Col lg="5">
                                   <div className="_text res-mb-40">
                                        <span className="mb-12 primary-color fw-500 d-block">See why firms choose TaxDome</span>
                                        <h2 className="mb-20">Industry leader trusted by 10,000+ firms in 25+ countries</h2>
                                        <p className="f-18 mb-20">TaxDome has 6,000+ reviews on Capterra, G2, and GetApp.</p>
                                        <div className="grid grid-cols-5 gap-2">
                                             <img src={smallBusinessLeader} />
                                             <img src={highPerformer} />
                                             <img src={leaderLeader} />
                                             <img src={accountingSmall} />
                                             <img src={momentumleaderLeader} />
                                        </div>
                                   </div>
                              </Col>
                              <Col lg="1"></Col>
                              <Col lg="6">
                                   <img alt="map" src={testimonial} />
                              </Col>
                         </Row>
                    </div>
               </div>
               <div className="website-container">
                    <div className="grid grid-cols-2 gap-[60px] max-[1360px]:gap-4 max-[1024px]:grid-cols-1">
                         <div className="flex justify-between flex-important gap-3 px-[40px] py-[56px] box-shadows rounded-2xl h-fit">
                              <div>
                                   <img src={quote} className="mb-3" />
                                   <p className="max-w-[470px] w-full">
                                        Our company uses Deupload, and I also use Box personally to store my documents, photos, videos,
                                        sensitive data etc. Deupload is amazing - so much more than just cloud storage. You can watch
                                        videos, share photos, scan documents, electronic sign and send documents, secure sensitive data.
                                   </p>
                              </div>
                              <div className="flex flex-col items-center w-[140px]">
                                   <img src={review1} className="mb-3 w-full h-full" />
                                   <div className="font-semibold text-[14px] text-center">Rodrigo Duarte</div>
                                   <div className="text-[10px]">Graphic Designer</div>
                                   <div className="flex">
                                        <img src={starFill} className="w-full h-full" />
                                        <img src={starFill} />
                                        <img src={starFill} />
                                        <img src={starFill} />
                                        <img src={starEmpty} />
                                   </div>
                              </div>
                         </div>
                         <div className="flex justify-between flex-important gap-3 px-[40px] py-[56px] box-shadows rounded-2xl h-fit">
                              <div>
                                   <img src={quote} className="mb-3" />
                                   <p className="max-w-[470px] w-full">
                                        Our company uses Deupload, and I also use Box personally to store my documents, photos, videos,
                                        sensitive data etc. Deupload is amazing - so much more than just cloud storage. You can watch
                                        videos, share photos, scan documents, electronic sign and send documents, secure sensitive data.
                                   </p>
                              </div>
                              <div className="flex flex-col items-center w-[140px]">
                                   <img src={review1} className="mb-3 w-full h-full" />
                                   <div className="font-semibold text-[14px] text-center">Rodrigo Duarte</div>
                                   <div className="text-[10px]">Graphic Designer</div>
                                   <div className="flex">
                                        <img src={starFill} className="w-full h-full" />
                                        <img src={starFill} />
                                        <img src={starFill} />
                                        <img src={starFill} />
                                        <img src={starEmpty} />
                                   </div>
                              </div>
                         </div>
                         <div className="flex justify-between flex-important gap-3 px-[40px] py-[56px] box-shadows rounded-2xl h-fit">
                              <div>
                                   <img src={quote} className="mb-3" />
                                   <p className="max-w-[470px] w-full">
                                        Our company uses Deupload, and I also use Box personally to store my documents, photos, videos,
                                        sensitive data etc. Deupload is amazing - so much more than just cloud storage. You can watch
                                        videos, share photos, scan documents, electronic sign and send documents, secure sensitive data.
                                   </p>
                              </div>
                              <div className="flex flex-col items-center w-[140px]">
                                   <img src={review1} className="mb-3 w-full h-full" />
                                   <div className="font-semibold text-[14px] text-center">Rodrigo Duarte</div>
                                   <div className="text-[10px]">Graphic Designer</div>
                                   <div className="flex">
                                        <img src={starFill} className="w-full h-full" />
                                        <img src={starFill} />
                                        <img src={starFill} />
                                        <img src={starFill} />
                                        <img src={starEmpty} />
                                   </div>
                              </div>
                         </div>
                         <div className="flex justify-between flex-important gap-3 px-[40px] py-[56px] box-shadows rounded-2xl h-fit">
                              <div>
                                   <img src={quote} className="mb-3" />
                                   <p className="max-w-[470px] w-full">
                                        Our company uses Deupload, and I also use Box personally to store my documents, photos, videos,
                                        sensitive data etc. Deupload is amazing - so much more than just cloud storage. You can watch
                                        videos, share photos, scan documents, electronic sign and send documents, secure sensitive data.
                                   </p>
                              </div>
                              <div className="flex flex-col items-center w-[140px]">
                                   <img src={review1} className="mb-3 w-full h-full" />
                                   <div className="font-semibold text-[14px] text-center">Rodrigo Duarte</div>
                                   <div className="text-[10px]">Graphic Designer</div>
                                   <div className="flex">
                                        <img src={starFill} className="w-full h-full" />
                                        <img src={starFill} />
                                        <img src={starFill} />
                                        <img src={starFill} />
                                        <img src={starEmpty} />
                                   </div>
                              </div>
                         </div>
                         <div className="flex justify-between flex-important gap-3 px-[40px] py-[56px] box-shadows rounded-2xl h-fit">
                              <div>
                                   <img src={quote} className="mb-3" />
                                   <p className="max-w-[470px] w-full">
                                        Our company uses Deupload, and I also use Box personally to store my documents, photos, videos,
                                        sensitive data etc. Deupload is amazing - so much more than just cloud storage. You can watch
                                        videos, share photos, scan documents, electronic sign and send documents, secure sensitive data.
                                   </p>
                              </div>
                              <div className="flex flex-col items-center w-[140px]">
                                   <img src={review1} className="mb-3 w-full h-full" />
                                   <div className="font-semibold text-[14px] text-center">Rodrigo Duarte</div>
                                   <div className="text-[10px]">Graphic Designer</div>
                                   <div className="flex">
                                        <img src={starFill} className="w-full h-full" />
                                        <img src={starFill} />
                                        <img src={starFill} />
                                        <img src={starFill} />
                                        <img src={starEmpty} />
                                   </div>
                              </div>
                         </div>
                         <div className="flex justify-between flex-important gap-3 px-[40px] py-[56px] box-shadows rounded-2xl h-fit">
                              <div>
                                   <img src={quote} className="mb-3" />
                                   <p className="max-w-[470px] w-full">
                                        Our company uses Deupload, and I also use Box personally to store my documents, photos, videos,
                                        sensitive data etc. Deupload is amazing - so much more than just cloud storage. You can watch
                                        videos, share photos, scan documents, electronic sign and send documents, secure sensitive data.
                                   </p>
                              </div>
                              <div className="flex flex-col items-center w-[140px]">
                                   <img src={review1} className="mb-3 w-full h-full" />
                                   <div className="font-semibold text-[14px] text-center">Rodrigo Duarte</div>
                                   <div className="text-[10px]">Graphic Designer</div>
                                   <div className="flex">
                                        <img src={starFill} className="w-full h-full" />
                                        <img src={starFill} />
                                        <img src={starFill} />
                                        <img src={starFill} />
                                        <img src={starEmpty} />
                                   </div>
                              </div>
                         </div>
                         <div className="flex justify-between flex-important gap-3 px-[40px] py-[56px] box-shadows rounded-2xl h-fit mb-16">
                              <div>
                                   <img src={quote} className="mb-3" />
                                   <p className="max-w-[470px] w-full">
                                        Our company uses Deupload, and I also use Box personally to store my documents, photos, videos,
                                        sensitive data etc. Deupload is amazing - so much more than just cloud storage. You can watch
                                        videos, share photos, scan documents, electronic sign and send documents, secure sensitive data.
                                   </p>
                              </div>
                              <div className="flex flex-col items-center w-[140px]">
                                   <img src={review1} className="mb-3 w-full h-full" />
                                   <div className="font-semibold text-[14px] text-center">Rodrigo Duarte</div>
                                   <div className="text-[10px]">Graphic Designer</div>
                                   <div className="flex">
                                        <img src={starFill} className="w-full h-full" />
                                        <img src={starFill} />
                                        <img src={starFill} />
                                        <img src={starFill} />
                                        <img src={starEmpty} />
                                   </div>
                              </div>
                         </div>
                         <div className="flex justify-between flex-important gap-3 px-[40px] py-[56px] box-shadows rounded-2xl h-fit mb-16">
                              <div>
                                   <img src={quote} className="mb-3" />
                                   <p className="max-w-[470px] w-full">
                                        Our company uses Deupload, and I also use Box personally to store my documents, photos, videos,
                                        sensitive data etc. Deupload is amazing - so much more than just cloud storage. You can watch
                                        videos, share photos, scan documents, electronic sign and send documents, secure sensitive data.
                                   </p>
                              </div>
                              <div className="flex flex-col items-center w-[140px]">
                                   <img src={review1} className="mb-3 w-full h-full" />
                                   <div className="font-semibold text-[14px] text-center">Rodrigo Duarte</div>
                                   <div className="text-[10px]">Graphic Designer</div>
                                   <div className="flex">
                                        <img src={starFill} className="w-full h-full" />
                                        <img src={starFill} />
                                        <img src={starFill} />
                                        <img src={starFill} />
                                        <img src={starEmpty} />
                                   </div>
                              </div>
                         </div>
                         {add ? (
                              <>
                                   <div className="flex justify-between flex-important gap-3 px-[40px] py-[56px] box-shadows rounded-2xl h-fit">
                                        <div>
                                             <img src={quote} className="mb-3" />
                                             <p className="max-w-[470px] w-full">
                                                  Our company uses Deupload, and I also use Box personally to store my documents, photos,
                                                  videos, sensitive data etc. Deupload is amazing - so much more than just cloud storage.
                                                  You can watch videos, share photos, scan documents, electronic sign and send documents,
                                                  secure sensitive data.
                                             </p>
                                        </div>
                                        <div className="flex flex-col items-center w-[140px]">
                                             <img src={review1} className="mb-3 w-full h-full" />
                                             <div className="font-semibold text-[14px] text-center">Rodrigo Duarte</div>
                                             <div className="text-[10px]">Graphic Designer</div>
                                             <div className="flex">
                                                  <img src={starFill} className="w-full h-full" />
                                                  <img src={starFill} />
                                                  <img src={starFill} />
                                                  <img src={starFill} />
                                                  <img src={starEmpty} />
                                             </div>
                                        </div>
                                   </div>
                                   <div className="flex justify-between flex-important gap-3 px-[40px] py-[56px] box-shadows rounded-2xl h-fit mb-16">
                                        <div>
                                             <img src={quote} className="mb-3" />
                                             <p className="max-w-[470px] w-full">
                                                  Our company uses Deupload, and I also use Box personally to store my documents, photos,
                                                  videos, sensitive data etc. Deupload is amazing - so much more than just cloud storage.
                                                  You can watch videos, share photos, scan documents, electronic sign and send documents,
                                                  secure sensitive data.
                                             </p>
                                        </div>
                                        <div className="flex flex-col items-center w-[140px]">
                                             <img src={review1} className="mb-3 w-full h-full" />
                                             <div className="font-semibold text-[14px] text-center">Rodrigo Duarte</div>
                                             <div className="text-[10px]">Graphic Designer</div>
                                             <div className="flex">
                                                  <img src={starFill} className="w-full h-full" />
                                                  <img src={starFill} />
                                                  <img src={starFill} />
                                                  <img src={starFill} />
                                                  <img src={starEmpty} />
                                             </div>
                                        </div>
                                   </div>
                              </>
                         ) : (
                              ""
                         )}
                    </div>
                    <div className="w-full text-center mb-[220px]">
                         <button
                              className="bg-[#1E4387] px-14 py-3 rounded-2xl text-white text-[24px] font-medium"
                              onClick={() => handleAdd(true)}
                         >
                              See {add ? "Less" : "More"}
                         </button>
                    </div>
               </div>
          </>
     );
}

export default Testimonials;
