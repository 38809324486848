import React from "react";
import { Col, Row } from "react-bootstrap";
import AboutImg from "../../assets/img/AboutImg.png";

function About() {
     return (
          <>
               <div className="website-container">
                    <Row className="align-items-center">
                         <Col lg="6">
                              <div className="me-xl-5">
                                   <div className="_text mb-50">
                                        <span className="mb-12 primary-color fw-500 d-block">A letter from the founder</span>
                                        <h2 className="mb-30">Who we are and why we exist</h2>
                                        <p>The Trackmydoc story is one about the transformative power of technology to a tax business.</p>
                                        <p>
                                             About 8 years ago, my brother and I built a custom software system for a tax practice, and
                                             watched it grow from 10 to over 70 staff in as much time. The firm growth trajectory was
                                             amazing — the firm grew immensely while becoming more agile, more efficient, more organized and
                                             ultimately, more profitable. Its clients were more satisfied, reporting that the new interface
                                             made the company more trustworthy and pleasant to work with.
                                        </p>
                                        <p>
                                             We realized we had a “secret sauce” to building operational success, and if we could make our
                                             proprietary software available to other firms, they would stand to benefit as well. And that’s
                                             why we launched Trackmydoc in March 2017.
                                        </p>
                                        <p>
                                             Trackmydoc is a technology company born out of deep understanding of Tax and Accounting
                                             business needs. Our mission (and passion) is improving your relationship with the work you do
                                             and the people you work with, making the day-to-day easier and your bottom line better.
                                        </p>
                                        <p>
                                             We exist to service companies of every size and shape — from sole proprietor to 20+ staff.
                                             Leveraging cloud-based software to automate menial tasks and free up your valuable time to
                                             focus on what matters most. Centralizing and streamlining communications with clients, storing
                                             important documents securely and invoicing with ease. That’s Trackmydoc.
                                        </p>
                                        <p>
                                             Our goal is to make your team focused, productive, organized, and most importantly, happy.
                                             Trackmydoc is the most intuitive, all-in-one cloud-based solution for tax practice management.
                                             Best-in-class software that is accessible no matter how large or small your tax business is.
                                        </p>
                                        <p>Let’s take your tax practice to a better place with Trackmydoc, together. </p>
                                   </div>
                              </div>
                         </Col>
                         <Col lg="6">
                              <div className="">
                                   <div className="combine-picture text-center p-5 my-5">
                                        <img src={AboutImg} />
                                   </div>
                              </div>
                         </Col>
                    </Row>
               </div>
          </>
     );
}

export default About;
