import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import logo from "./logo.svg";
import Footer from "./components/Footer.js";
import Home from "./components/Home.js";
import CRMApp from "./components/CRMApp.js";
import TaxOrganizers from "./components/TaxOrganizers.js";
import TimeBilling from "./components/TimeBilling.js";
import WebsiteBranding from "./components/WebsiteBranding.js";
import TeamManagement from "./components/TeamManagement.js";
import DocumentManagement from "./components/DocumentManagement.js";
import SecureMessages from "./components/SecureMessages.js";
import ClientPortal from "./components/ClientPortal.js";
import Inbox from "./components/Inbox.js";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Header from "./components/Header";
import ScrollToTop from "./components/ScrollToTop.js";
import Pricing from "./components/Pricing.js";
import ContectUs from "./components/ContectUs.js";
import About from "./components/About/About.js";
import OurValue from "./components/About/OurValue.js";
import Terms from "./components/About/Terms.js";
import Testimonials from "./components/About/Testimonials.js";
import ProductOverview from "./components/Product/ProductOverview.js";
import IntegrationOverview from "./components/Integration/IntegrationOverview.js";
import PaymentProcessor from "./components/Integration/PaymentProcessor.js";

function App() {
     return (
          <Router>
               <div className="App">
                    <Header className="App-header"></Header>
                    <div className="main-content">
                         <ScrollToTop />
                         <Routes>
                              <Route path="/" element={<Home />} />
                              <Route path="/crm" element={<CRMApp />} />
                              <Route path="/tax-organizers" element={<TaxOrganizers />} />
                              <Route path="/time-billing" element={<TimeBilling />} />
                              <Route path="/website-branding" element={<WebsiteBranding />} />
                              <Route path="/team-management" element={<TeamManagement />} />
                              <Route path="/document-management" element={<DocumentManagement />} />
                              <Route path="/secure-messages" element={<SecureMessages />} />
                              <Route path="/client-portal" element={<ClientPortal />} />
                              <Route path="/inbox" element={<Inbox />} />
                              <Route path="/pricing" element={<Pricing />} />
                              <Route path="/contact" element={<ContectUs />} />
                              <Route path="/about" element={<About />} />
                              <Route path="/our-values" element={<OurValue />} />
                              <Route path="/terms-of-services" element={<Terms />} />
                              <Route path="/testimonials" element={<Testimonials />} />
                              <Route path="/product-overview" element={<ProductOverview />} />
                              <Route path="/integration-overview" element={<IntegrationOverview />} />
                              <Route path="/payment-processor" element={<PaymentProcessor />} />
                         </Routes>
                    </div>
                    <Footer></Footer>
               </div>
          </Router>
     );
}

export default App;
