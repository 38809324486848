import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import appstore from "../assets/img/app-store.png";
import googleplay from "../assets/img/google-play.png";

import facebook from "../assets/img/facebook.svg";
import twitter from "../assets/img/twitter.svg";
import insta from "../assets/img/insta.svg";
import linkedin from "../assets/img/in.svg";
import { Link, useNavigate } from "react-router-dom";

function Footer() {
     const navigate = useNavigate();

     // const navigateToSection = (path, sectionId) => {
     //      navigate(path); // Navigate to the Terms page
     //      setTimeout(() => {
     //           const section = document.getElementById(sectionId);
     //           if (section) {
     //                section.scrollIntoView({ behavior: "smooth" }); // Scroll to the section
     //           }
     //      }, 100);
     // };

     const manageClick = (data) => {
          navigate("/terms-of-services", { state: { change: data } });
          // window.location.reload();
          window.scrollTo(0, 0);
     };
     // const manageClick2 = ()=>{
     //      navigate("/terms-of-services",{state:{change2:""}})
     // }
     return (
          <footer>
               <div className="website-container">
                    <Row className="row row-cols-2 row-cols-sm-2 row-cols-md-2 row-cols-lg-4 row-cols-xl-4">
                         <Col>
                              <div className="footer-list">
                                   <div className="title">Products</div>
                                   <div className="list">
                                        <ul>
                                             <li>
                                                  <Link to="/crm">CRM & Apps</Link>
                                             </li>
                                             <li>
                                                  <Link to="/tax-organizers">Tax Organizers</Link>
                                             </li>
                                             <li>
                                                  <Link to="/time-billing">Time & Billing</Link>
                                             </li>
                                             <li>
                                                  <Link to="/website-branding">Website & Branding</Link>
                                             </li>
                                             <li>
                                                  <Link to="/team-management">Team Management Document</Link>
                                             </li>
                                             <li>
                                                  <Link to="/document-management">Management Secure Messages</Link>
                                             </li>
                                             <li>
                                                  <Link to="/client-portal">Client Portal</Link>
                                             </li>
                                             <li>
                                                  <Link to="/inbox">Inbox</Link>
                                             </li>
                                        </ul>
                                   </div>
                              </div>
                         </Col>
                         <Col>
                              <div className="footer-list">
                                   <div className="title">Company</div>
                                   <div className="list">
                                        <ul>
                                             <li>
                                                  <Link to="/about">About Us</Link>
                                             </li>
                                             <li>
                                                  <Link to="/crm">Product</Link>
                                             </li>
                                             <li>
                                                  <Link to="/team-management">Integrations</Link>
                                             </li>
                                             <li>
                                                  <Link to="/pricing">Pricing</Link>
                                             </li>
                                        </ul>
                                   </div>
                              </div>
                              <div className="footer-list">
                                   <div className="title">Compare</div>
                                   <div className="list">
                                        <ul>
                                             <li>
                                                  <a>Master Table</a>
                                             </li>
                                             <li>
                                                  <a>Trackmydoc vs Canopy</a>
                                             </li>
                                        </ul>
                                   </div>
                              </div>
                         </Col>
                         <Col>
                              <div className="footer-list">
                                   <div className="title">Policies</div>
                                   <div className="list">
                                        <ul>
                                             <li onClick={() => manageClick("Terms of Service")}>
                                                  <a>Terms of Service</a>
                                                  {/* <Link to="/terms-of-services">Terms of Service</Link> */}
                                             </li>
                                             <li onClick={() => manageClick("Privacy Policy")}>
                                                  <a>Privacy Policy</a>
                                                  {/* <Link to="/terms-of-services">Privacy Policy</Link> */}
                                             </li>
                                             <li onClick={() => manageClick("Cookie Policy")}>
                                                  <a>Cookies</a>
                                             </li>
                                             {/* <button onClick={() => navigateToSection("/terms-of-services", "privacy-policy")}>
                                                  Privacy Policy
                                             </button>
                                             <button onClick={() => navigateToSection("/terms-of-services", "cookie-policy")}>
                                                  Cookies
                                             </button> */}
                                        </ul>
                                   </div>
                              </div>
                         </Col>
                         <Col>
                              <Link to="/contact" className="btn btn-primary-other">
                                   Request Demo
                              </Link>
                              <div className="app">
                                   <p>Client mobile app</p>
                                   <a className="mb-3 d-block">
                                        <img alt="google play" src={googleplay} />
                                   </a>
                                   <a className="d-block">
                                        <img alt="apps tore" src={appstore} />
                                   </a>
                              </div>
                         </Col>
                    </Row>
               </div>
               <div className="footer-bottom">
                    <Container>
                         <Row>
                              <Col md={6}>
                                   <p>1178 Broadway NY, NY 10001. 833-TRACKMYDOC</p>
                              </Col>
                              <Col md={6} className="text-md-end">
                                   <ol className="social-media">
                                        <li>
                                             <a>
                                                  <img alt="facebook" src={facebook} />
                                             </a>
                                        </li>
                                        <li>
                                             <a>
                                                  <img alt="twitter" src={twitter} />
                                             </a>
                                        </li>
                                        <li>
                                             <a>
                                                  <img alt="insta" src={insta} />
                                             </a>
                                        </li>
                                        <li>
                                             <a>
                                                  <img alt="linkedin" src={linkedin} />
                                             </a>
                                        </li>
                                   </ol>
                              </Col>
                         </Row>
                    </Container>
               </div>
          </footer>
     );
}
export default Footer;
