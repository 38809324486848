import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import call from "../assets/img/call.svg";
import sms from "../assets/img/sms.svg";
import location from "../assets/img/location.svg";

function ContactUs() {
     const formik = useFormik({
          initialValues: {
               firstName: "",
               lastName: "",
               email: "",
               contactNo: "",
               message: "",
          },
          validationSchema: Yup.object({
               firstName: Yup.string().required("First name is required"),
               lastName: Yup.string().required("Last name is required"),
               email: Yup.string().email("Invalid email address").required("Email is required"),
               contactNo: Yup.string()
                    .matches(/^[\+\d\(\)\-\s]*$/, "Phone number can include digits, spaces, and special characters (+, -, (), etc.)")
                    .test("is-not-string", "Phone number must not contain letters", (value) => !/[a-zA-Z]/.test(value))
                    .required("Phone number is required"),
               message: Yup.string().required("Message is required"),
          }),

          onSubmit: async (values, { resetForm }) => {
               try {
                    const body = {
                         first_name: values.firstName,
                         last_name: values.lastName,
                         email: values.email,
                         contact_no: values.contactNo,
                         message: values.message,
                    };

                    const response = await fetch("https://staging-backend.trackmydoc.com/api/store-get-touch", {
                         method: "POST",
                         headers: {
                              "Content-Type": "application/json",
                         },
                         body: JSON.stringify(body),
                    });

                    const data = await response.json();
                    console.log("Response Status:", response.status);
                    console.log("Response Data:", data);

                    if (response.ok) {
                         toast.success("Message sent successfully!", {
                              position: "top-right",
                              autoClose: 3000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              theme: "light",
                         });
                         resetForm();
                    } else {
                         toast.error(data.message || "Failed to send message. Please try again.", {
                              position: "top-right",
                              autoClose: 3000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              theme: "light",
                         });
                    }
               } catch (error) {
                    console.error("Error:", error);
                    toast.error("An error occurred. Please try again later.", {
                         position: "top-right",
                         autoClose: 3000,
                         hideProgressBar: false,
                         closeOnClick: true,
                         pauseOnHover: true,
                         draggable: true,
                         progress: undefined,
                         theme: "light",
                    });
               }
          },
     });
     // console.log("Payload being sent:", body);

     return (
          <div className="bg-[#FAFAFA] py-[136px]">
               <ToastContainer style={{ borderRadius: "30px" }} />
               <div className="website-container">
                    <div className="max-w-[728px] w-full mb-[60px]">
                         <div className="mb-5 text-[50px] font-bold max-[425px]:text-[25px] max-[425px]:mb-1">Get In Touch</div>
                         <p>
                         At Track MyDoc, we value your input and are dedicated to providing the best experience. Drop us a message, and we’ll respond as quickly as possible.
                         </p>
                    </div>
                    <div className="bg-[#EFF1F4] p-[60px] flex gap-[60px] max-[1010px]:gap-[30px] max-[972px]:block max-[490px]:px-[30px] max-[395px]:px-[20px] max-[350px]:px-[10px]">
                         <div className="bg-white rounded-2xl p-[60px] max-w-[553px] w-full max-[972px]:max-w-full max-[972px]:text-center max-[972px]:mb-[50px] max-[546px]:px-[30px] max-[395px]:px-[20px] max-[350px]:px-[10px]">
                              <div className="mb-[73px]">
                                   <h5 className="text-[32px] font-semibold mb-3 leading-[40px]">Contact Information</h5>
                                   <p className="text-[#7D8592] text-[18px] font-normal">Have a question? Message us!</p>
                              </div>
                              <div className="space-y-10">
                                   <div className="flex items-center gap-4 max-[972px]:justify-center">
                                        <img src={call} alt="Call Icon" />
                                        <div>
                                             <div className="font-medium max-[425px]:text-[14px]">+8869532545</div>
                                             <div className="font-medium max-[425px]:text-[14px]">+9327854847</div>
                                        </div>
                                   </div>
                                   <div className="flex items-center gap-4 max-[430px]:gap-1 max-[972px]:justify-center">
                                        <img src={sms} alt="SMS Icon" />
                                        <div className="font-medium max-[425px]:text-[14px]">trackmydoc01@gmail.com</div>
                                   </div>
                                   <div className="flex items-center gap-4 max-[430px]:gap-1 max-[972px]:justify-center">
                                        <img src={location} alt="Location Icon" />
                                        <div className="font-medium max-[425px]:text-[14px]">1178 Broadway NY, NY 10001.</div>
                                   </div>
                              </div>
                         </div>
                         <form onSubmit={formik.handleSubmit} className="w-full">
                              <div className="mt-10 space-y-[60px]">
                                   <div className="flex gap-[60px] max-[524px]:block">
                                        <div className="w-full max-[524px]:mb-[60px]">
                                             <p className="text-[20px] font-medium">First Name</p>
                                             <input
                                                  type="text"
                                                  name="firstName"
                                                  value={formik.values.firstName}
                                                  onChange={formik.handleChange}
                                                  onBlur={formik.handleBlur}
                                                  className="border-t-0 border-r-0 border-l-0 border-b bg-transparent border-[#8D8D8D] w-full"
                                             />
                                             {formik.touched.firstName && formik.errors.firstName && (
                                                  <div className="text-red-500 text-sm mt-1">{formik.errors.firstName}</div>
                                             )}
                                        </div>
                                        <div className="w-full">
                                             <p className="text-[20px] font-medium">Last Name</p>
                                             <input
                                                  type="text"
                                                  name="lastName"
                                                  value={formik.values.lastName}
                                                  onChange={formik.handleChange}
                                                  onBlur={formik.handleBlur}
                                                  className="border-t-0 border-r-0 border-l-0 border-b bg-transparent border-[#8D8D8D] w-full"
                                             />
                                             {formik.touched.lastName && formik.errors.lastName && (
                                                  <div className="text-red-500 text-sm mt-1">{formik.errors.lastName}</div>
                                             )}
                                        </div>
                                   </div>
                                   <div className="flex gap-[60px] max-[524px]:block">
                                        <div className="w-full max-[524px]:mb-[60px]">
                                             <p className="text-[20px] font-medium">Email</p>
                                             <input
                                                  type="email"
                                                  name="email"
                                                  value={formik.values.email}
                                                  onChange={formik.handleChange}
                                                  onBlur={formik.handleBlur}
                                                  className="border-t-0 border-r-0 border-l-0 border-b bg-transparent border-[#8D8D8D] w-full"
                                             />
                                             {formik.touched.email && formik.errors.email && (
                                                  <div className="text-red-500 text-sm mt-1">{formik.errors.email}</div>
                                             )}
                                        </div>
                                        <div className="w-full">
                                             <p className="text-[20px] font-medium">Phone number</p>
                                             <input
                                                  type="text"
                                                  name="contactNo"
                                                  value={formik.values.contactNo}
                                                  onChange={formik.handleChange}
                                                  onBlur={formik.handleBlur}
                                                  className="border-t-0 border-r-0 border-l-0 border-b bg-transparent border-[#8D8D8D] w-full"
                                             />
                                             {formik.touched.contactNo && formik.errors.contactNo && (
                                                  <div className="text-red-500 text-sm mt-1">{formik.errors.contactNo}</div>
                                             )}
                                        </div>
                                   </div>
                                   <div className="w-full">
                                        <p className="text-[20px] font-medium">Message</p>
                                        <textarea
                                             name="message"
                                             value={formik.values.message}
                                             onChange={formik.handleChange}
                                             onBlur={formik.handleBlur}
                                             className="border-t-0 border-r-0 border-l-0 border-b bg-transparent border-[#8D8D8D] w-full"
                                             rows={8}
                                        />
                                        {formik.touched.message && formik.errors.message && (
                                             <div className="text-red-500 text-sm mt-1">{formik.errors.message}</div>
                                        )}
                                   </div>
                                   <div>
                                        <button type="submit" className="py-[13px] px-6 bg-[#1E4387] rounded-xl text-white font-semibold">
                                             Send message
                                        </button>
                                   </div>
                              </div>
                         </form>
                    </div>
               </div>
          </div>
     );
}

export default ContactUs;
