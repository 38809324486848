import React from "react";
import { Accordion, Col, Row } from "react-bootstrap";
import acurrantlogo from "../assets/img/acurrant.svg";
import howtrackmydochelpedlogo from "../assets/img/how-trackmydoc-helped.svg";
import RioImg from "../assets/img/RIO.png";
import Security from "../assets/img/security.png";
import map from "../assets/img/map.png";
import PricingTable from "./pricingTable";
import smallBusinessLeader from "../assets/img/trusted/small-business-leader.svg";
import highPerformer from "../assets/img/trusted/highperformer.svg";
import leaderLeader from "../assets/img/trusted/leader-leader.svg";
import accountingSmall from "../assets/img/trusted/accounting-small-business-leader.svg";
import momentumleaderLeader from "../assets/img/trusted/momentumleader-leader.svg";
import mapBg from "../assets/img/mapBg.svg";

function Pricing() {
     return (
          <>
               <div className="bg-[#FAFAFA] py-[130px]">
                    <div className="website-container">
                         <div className="text-center">
                              <h1 className="text-[#000] text-[50px] text-center max-[425px]:text-[30px]">
                                   <span className="text-[#0BAFD4]">One Plan,</span> everything included
                              </h1>
                              <p className="text-[18px]">
                                   With no complicated tiers, modules, or fees per contact, you can feel
                                   <br /> confident you made the right choice.
                              </p>
                         </div>
                    </div>

                    <div className="website-container relative z-20">
                         <div className="mt-10">
                              <div className="text-center mb-20">
                                   <p className="text-[28px] text-[#000] font-semibold leading-8">Save with multiple-year subscriptions</p>
                                   <p className="text-[18px]">All plans billed upfront</p>
                              </div>
                         </div>
                         <div className="grid grid-cols-3 gap-[34px] max-[1400px]:grid-cols-1">
                              <div className=" py-[58px] px-10 border-2 border-[#0BAFD4] rounded-3xl">
                                   <div className="mb-[52px] text-[32px] font-semibold">Premium</div>
                                   <div className="flex gap-1">
                                        <p className="text-[48px] text-[#000] font-bold mb-2">$750</p>
                                        <p className="text-[20px] text-[#000] font-normal">/ year per user</p>
                                   </div>
                                   <div className="text-[20px] text-[#838696]">Equivalent to $63/month</div>
                                   <div>
                                        <button className="bg-[#1E4387] rounded-lg mt-12 text-white w-full py-[15px]">
                                             Save $50 per year
                                        </button>
                                        <div className="doted-border"></div>
                                   </div>
                                   <div className="text-[42px] font-semibold leading-[60px] max-[375px]:text-[30px] max-[375px]:leading-[30px]">
                                        2 year
                                        <br /> subscription
                                   </div>
                              </div>
                              <div className=" py-[58px] px-10 border-2 border-[#1E4387] rounded-3xl relative">
                                   <div className="mb-[52px] text-[32px] font-semibold max-[375px]:mt-3">Standard</div>
                                   <div className="flex gap-1">
                                        <p className="text-[48px] text-[#000] font-bold mb-2">$700</p>
                                        <p className="text-[20px] text-[#000] font-normal">/ year per user</p>
                                   </div>
                                   <div className="text-[20px] text-[#838696]">Equivalent to $58/month</div>
                                   <div>
                                        <button className="bg-[#1E4387] rounded-lg mt-12 text-white w-full py-[15px]">
                                             Save $50 per year
                                        </button>
                                        <div className="doted-border"></div>
                                   </div>
                                   <div className="text-[42px] font-semibold leading-[60px] max-[375px]:text-[30px] max-[375px]:leading-[30px]">
                                        3 year
                                        <br /> subscription
                                   </div>
                                   <div className="bg-[#1E4387] text-white text-[20px] px-[20px] py-[9px] absolute top-10 -right-5 popular max-[375px]:top-5">
                                        Most Popular
                                   </div>
                              </div>
                              <div className="py-[58px] px-10 border-2 border-[#0BAFD4] rounded-3xl">
                                   <div className="mb-[52px] text-[32px] font-semibold">Advance</div>
                                   <div className="flex gap-1">
                                        <p className="text-[48px] text-[#000] font-bold mb-2">$800</p>
                                        <p className="text-[20px] text-[#000] font-normal">/ year per user</p>
                                   </div>
                                   <div className="text-[20px] text-[#838696]">Equivalent to $68/month</div>
                                   <div>
                                        <button className="bg-[#1E4387] rounded-lg mt-12 text-white w-full py-[15px]">
                                             Save $50 per year
                                        </button>
                                        <div className="doted-border"></div>
                                   </div>
                                   <div className="text-[42px] font-semibold leading-[60px] max-[375px]:text-[30px] max-[375px]:leading-[30px]">
                                        1 year
                                        <br /> subscription
                                   </div>
                              </div>
                         </div>
                    </div>

                    <div className="globe common-margin relative z-10">
                         <div className="website-container">
                              <Row>
                                   <Col lg="5">
                                        <div className="_text res-mb-40">
                                             <span className="mb-12 primary-color fw-500 d-block">Global accounting platform</span>
                                             <h2 className="mb-20">Industry leader trusted by 10,000+ firms in 25+ countries</h2>
                                             <p className="f-18 mb-20">TaxDome has 6,000+ reviews on Capterra, G2, and GetApp.</p>
                                             <div className="grid grid-cols-5 gap-2">
                                                  <img src={smallBusinessLeader} />
                                                  <img src={highPerformer} />
                                                  <img src={leaderLeader} />
                                                  <img src={accountingSmall} />
                                                  <img src={momentumleaderLeader} />
                                             </div>
                                        </div>
                                   </Col>
                                   <Col lg="1"></Col>
                                   <Col lg="6">
                                        <img alt="map" src={map} />
                                   </Col>
                              </Row>
                         </div>
                         <div className="absolute -top-1/2 left-0 -z-10 max-[1024px]:-top-10 max-[768px]:top-1/2">
                              <img src={mapBg} />
                         </div>
                    </div>
                    <PricingTable />
                    {/* E-book section start */}
                    <div className="common-margin how-trackmydoc">
                         <div className="website-container">
                              <Row className="align-items-center">
                                   <Col lg={6} xl={7}>
                                        <div className="__text res-mb-40">
                                             <h2>How Trackmydoc Helped a 40-Person Accounting Firm Become More Efficient and Scalable</h2>

                                             <p className="mb-2">
                                                  <b className="secondary-color">Firm:</b> Accurint LLC
                                             </p>
                                             <p className="mb-30">
                                                  <b className="secondary-color">Services:</b> Accounting, bookkeeping, tax preparation
                                             </p>

                                             <a className="btn btn-primary me-3">Get the Free E-Book</a>
                                             <div className="mt-50">
                                                  <div className="mx-700">
                                                       <div className="counting-box text-center w-100">
                                                            <Row className="justify-content-between">
                                                                 <Col>
                                                                      <h4 className="primary-color fw-700">9,000+</h4>
                                                                      <p className="mb-0">Clients</p>
                                                                 </Col>
                                                                 <Col>
                                                                      <h4 className="primary-color fw-700">40+</h4>
                                                                      <p className="mb-0">Team Members</p>
                                                                 </Col>
                                                                 <Col>
                                                                      <h4 className="primary-color fw-700">6</h4>
                                                                      <p className="mb-0">Offices</p>
                                                                 </Col>
                                                            </Row>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                   </Col>
                                   <Col lg={6} xl={5} className="text-center">
                                        <a className="_picture">
                                             <div className="tooltip-popup">
                                                  <div>
                                                       <img src={acurrantlogo} alt="acurrantlogo" />
                                                  </div>
                                                  <div className="_right text-start">
                                                       <h5 className="mb-0 secondary-color">Barb Vanags</h5>
                                                       <p className="mb-0  secondary-color max-[320px]:text-[12px]">Managing Partner</p>
                                                  </div>
                                             </div>
                                             <img src={howtrackmydochelpedlogo} alt="How Trackmydoc helped" />
                                        </a>
                                   </Col>
                              </Row>
                         </div>
                    </div>
                    {/* E-book section end */}
                    {/* RIO Section start */}
                    <div className="website-container">
                         <div className="grid grid-cols-2 py-[120px] items-center max-[1000px]:grid-cols-1 max-[1000px]:justify-items-center max-[1000px]:text-center">
                              <div className="max-w-[700px] w-full">
                                   <div className="text-[50px] font-bold leading-[64px] mb-5 max-[425px]:text-[30px] max-[425px]:leading-[40px]">
                                        Calculate your ROI and cost savings with TaxDome
                                   </div>
                                   <p>
                                        With our all-in-one practice management system, accounting firms save $18,000+ per employee each
                                        year. Calculate how much your firm can save!
                                   </p>
                              </div>
                              <div className="">
                                   <img src={RioImg} />
                              </div>
                         </div>
                    </div>
                    {/* RIO Section end */}

                    {/* security Section Start */}
                    <div className="bg-[#EFF1F4] py-[120px]">
                         <div className="website-container">
                              <div className="grid grid-cols-2 items-center max-[1000px]:grid-cols-1 max-[1000px]:justify-items-center max-[1000px]:text-center">
                                   <div className="max-[1000px]:mb-3">
                                        <img src={Security} />
                                   </div>
                                   <div className="max-w-[700px] w-full">
                                        <div className="text-[50px] font-bold leading-[64px] mb-5 max-[425px]:text-[30px] max-[425px]:leading-[40px]">
                                             Offering the highest security standards
                                        </div>
                                        <p>
                                             At TaxDome, we’re committed to providing best-in-class services, including enterprise-grade
                                             security measures. TaxDome is SOC 2 compliant — we handle sensitive data with care, protecting
                                             your firm from fraud and breaches.
                                        </p>
                                   </div>
                              </div>
                         </div>
                    </div>
                    {/* security Section End */}

                    {/* FAQ Section start */}

                    <div className="website-container">
                         <div className="accordion common-margin">
                              <span className="mb-20 primary-color fw-500 d-block text-center">Your concerns, answered</span>
                              <h2 className="text-center mb-60">Frequently asked questions</h2>
                              <Accordion defaultActiveKey="1">
                                   <Accordion.Item eventKey="0">
                                        <Accordion.Header>What is accounting practice management software?</Accordion.Header>
                                        <Accordion.Body>
                                             No, training is 100 percent free. We provide unlimited support on the phone or by chat. Once
                                             you sign up with the trial, you'll receive a message from our team to schedule your one-on-one
                                             onboarding session with screen sharing.
                                        </Accordion.Body>
                                   </Accordion.Item>
                                   <Accordion.Item eventKey="1">
                                        <Accordion.Header>Are there onboarding fees?</Accordion.Header>
                                        <Accordion.Body>
                                             No, training is 100 percent free. We provide unlimited support on the phone or by chat. Once
                                             you sign up with the trial, you'll receive a message from our team to schedule your one-on-one
                                             onboarding session with screen sharing.
                                        </Accordion.Body>
                                   </Accordion.Item>
                                   <Accordion.Item eventKey="2">
                                        <Accordion.Header>
                                             What is the estimated time frame for setting up Trackmydoc from start to finish?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                             No, training is 100 percent free. We provide unlimited support on the phone or by chat. Once
                                             you sign up with the trial, you'll receive a message from our team to schedule your one-on-one
                                             onboarding session with screen sharing.
                                        </Accordion.Body>
                                   </Accordion.Item>
                                   <Accordion.Item eventKey="3">
                                        <Accordion.Header>
                                             Is Trackmydoc for managing the office and facilitating internal and external communication but
                                             not preparing tax returns?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                             No, training is 100 percent free. We provide unlimited support on the phone or by chat. Once
                                             you sign up with the trial, you'll receive a message from our team to schedule your one-on-one
                                             onboarding session with screen sharing.
                                        </Accordion.Body>
                                   </Accordion.Item>
                                   <Accordion.Item eventKey="4">
                                        <Accordion.Header>What is included into your trial?</Accordion.Header>
                                        <Accordion.Body>
                                             No, training is 100 percent free. We provide unlimited support on the phone or by chat. Once
                                             you sign up with the trial, you'll receive a message from our team to schedule your one-on-one
                                             onboarding session with screen sharing.
                                        </Accordion.Body>
                                   </Accordion.Item>
                              </Accordion>
                         </div>
                    </div>
                    {/* FAQ Section end */}
               </div>
          </>
     );
}

export default Pricing;
