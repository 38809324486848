import React from "react";

function IntegrationOverview() {
     return (
          <>
               <div className="website-container">
                    <div className="px-16 py-11">
                         <div className="mb-14">
                              <h1 className="text-4xl font-bold mb-4">Automate Your Workflow with TrackmyDoc’s CRM Solutions</h1>
                              <p className="text-lg text-gray-700">
                                   Establish the most trusted link between TrackmyDoc and your favorite schedulers, payment providers, tax
                                   programs, and more! Automate your workflows to run your enterprise efficiently and productively. Up your
                                   game of transactions and client coordination with the best Cloud CRM solutions, digital tax preparation
                                   tools, client management tools, and more.
                              </p>
                         </div>

                         <div className="mb-14">
                              <h2 className="text-2xl font-semibold mb-4">Why Choose TrackmyDoc CRM?</h2>
                              <ul className="list-disc list-inside space-y-2 text-gray-700">
                                   <li>
                                        <strong>Seamless Integration:</strong> Connect all your essential tools in one place for efficient
                                        communication and collaboration.
                                   </li>
                                   <li>
                                        <strong>Workflow Automation:</strong> Automate repetitive tasks to save time and reduce costs.
                                   </li>
                                   <li>
                                        <strong>AI-Powered Insights:</strong> Make informed decisions with data-driven analytics tailored to
                                        your needs.
                                   </li>
                              </ul>
                         </div>

                         <div className="mb-14">
                              <h2 className="text-2xl font-semibold mb-4">Features to Transform Your Practice</h2>
                              <div className="space-y-6">
                                   <div>
                                        <h3 className="text-xl font-semibold">1. Time-Tracking Tools</h3>
                                        <p className="text-gray-700">
                                             Keep track of every task with our advanced time-tracking software. Allocate resources
                                             effectively and monitor progress in real-time.
                                        </p>
                                   </div>
                                   <div>
                                        <h3 className="text-xl font-semibold">2. Recurring Invoices</h3>
                                        <p className="text-gray-700">
                                             Simplify your billing process with automated recurring invoice solutions. Ensure timely
                                             payments and maintain a healthy cash flow.
                                        </p>
                                   </div>
                                   <div>
                                        <h3 className="text-xl font-semibold">3. Integrated Workflow Management</h3>
                                        <p className="text-gray-700">
                                             Connect your tax programs, schedulers, and payment providers directly to TrackmyDoc. Automate
                                             your workflow and run your firm with unparalleled efficiency.
                                        </p>
                                   </div>
                                   <div>
                                        <h3 className="text-xl font-semibold">4. Team Collaboration</h3>
                                        <p className="text-gray-700">
                                             Empower your team with tools designed for accountability and collaboration. Assign tasks, track
                                             milestones, and achieve goals faster.
                                        </p>
                                   </div>
                              </div>
                         </div>

                         <div className="mb-14">
                              <h2 className="text-2xl font-semibold mb-4">Benefits of TrackmyDoc</h2>
                              <ul className="list-disc list-inside space-y-2 text-gray-700">
                                   <li>
                                        <strong>Streamlined Operations:</strong> Simplify your processes and reduce manual effort.
                                   </li>
                                   <li>
                                        <strong>Cost Efficiency:</strong> Minimize overhead costs with smart automation.
                                   </li>
                                   <li>
                                        <strong>Enhanced Client Relationships:</strong> Deliver accurate, timely, and reliable services to
                                        your clients.
                                   </li>
                                   <li>
                                        <strong>Scalability:</strong> Perfect for firms of all sizes, from individuals to large teams.
                                   </li>
                              </ul>
                         </div>
                    </div>

                    {/* <div className="text-center">
                <h2 className="text-2xl font-semibold mb-4">Get Started Today</h2>
                <p className="text-gray-700 mb-6">
                    Revolutionize your practice with TrackmyDoc. Automate your workflow, integrate your tools, and
                    enhance your productivity. Contact us today to learn more and schedule a demo.
                </p>
                <button className="bg-blue-600 text-white px-6 py-2 rounded hover:bg-blue-700">
                    Schedule a Demo
                </button>
            </div> */}
               </div>
          </>
     );
}

export default IntegrationOverview;
